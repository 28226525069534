const {
  serviceOptions: commonServiceOptions,
} = require('./__generated__/CommonApi');
const {
  serviceOptions: frontServiceOptions,
} = require('./__generated__/FrontApi');
const { default: axios } = require('axios');
const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const { configure } = require('mobx');

if (typeof window !== 'undefined') {
  if (window.location.hostname === 'm.unicef.or.kr') {
    window.location.href = "https://www.unicef.or.kr"
  }
}

// dayjs 초기화
require('dayjs/locale/ko');

dayjs.locale('ko');
dayjs.extend(customParseFormat);

// axios 초기화
const instance = axios.create({
  baseURL: process.env.BACKEND_URL,
  timeout: 300000,
  paramsSerializer(paramObj) {
    const params = new URLSearchParams();
    // eslint-disable-next-line no-restricted-syntax
    for (const key in paramObj) {
      if (paramObj[key] !== undefined) {
        // 배열형태 데이터 처리
        if (Array.isArray(paramObj[key])) {
          paramObj[key].map((v) => params.append(key, v));
        } else {
          params.append(key, paramObj[key]);
        }
      }
    }
    return params.toString();
  },
});
// 헤더 토큰 세팅
instance.interceptors.request.use((config) => {
  if (typeof window === `undefined`) {
    return config;
  }
  // 브라우저 환경에서는 항상 localStorage에서 토큰을 읽어옴
  const token = localStorage.getItem(`token`);
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.common['X-AUTH-TOKEN'] = token;
  }
  return config;
});

frontServiceOptions.axios = instance;
commonServiceOptions.axios = instance;

// mobx 초기화
// IE11 호환 설정
configure({ useProxies: 'never' });
