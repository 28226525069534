// 브라우저 환경
const { GoogleOAuthProvider } = require('@react-oauth/google');
const React = require('react');

// 초기화 스크립트 로드
require('./src/init');

// @react-oauth/google provider 세팅
exports.wrapRootElement = ({ element }) => (
  <GoogleOAuthProvider clientId={process.env.GOOGLE_LOGIN_CLIENT_ID || ``}>
    {element}
  </GoogleOAuthProvider>
);
