/** Generate by swagger-axios-codegen */
// @ts-ignore
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-ignore
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AdminLoginControllerService {
  /**
   * 관리자 로그인
   */
  static adminLoginUsingPost(
    params: {
      /** 관리자 아이디 */
      id: string;
      /** 비밀번호 */
      password: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/admin/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'], password: params['password'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 관리자 로그아웃
   */
  static adminLogoutUsingPost(
    params: {
      /** 관리자 ID */
      adminId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/admin/logout';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { adminId: params['adminId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 관리자 JWT Access 토큰 갱신
   */
  static refreshTokenUsingPost(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/admin/refresh';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * UIIS에서 로그인 처리 완료 후 발급한 키로 토큰을 생성 로그인
   */
  static uiisAdminLoginUsingGet(
    params: {
      /** uiis로그인 ID */
      id: string;
      /** uiis로그인시 발급한 키 */
      secKey: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/admin/uiis/login';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], secKey: params['secKey'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BoardCommonControllerService {
  /**
   * 게시글 삭제
   */
  static boDeleteUsingPost(
    params: {
      /** 인덱스 번호 */
      boardIndexNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/common/board/delete/{boardIndexNumber}';
      url = url.replace('{boardIndexNumber}', params['boardIndexNumber'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * TB_BOARD 카테고리별 조회 페이징 및 검색조건
   */
  static boDetailUsingGet(
    params: {
      /** 인덱스 번호 */
      boardIndexNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/common/board/detail/{boardIndexNumber}';
      url = url.replace('{boardIndexNumber}', params['boardIndexNumber'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 해시태그 목록 조회
   */
  static getHashtagListUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/common/board/hashtag';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 게시글 작성
   */
  static boInsertUsingPost(
    params: {
      /** 첨부파일 번호(파일) */
      attGrpNo?: string;
      /** 첨부파일 번호(모바일배너) */
      attGrpNoMobile?: string;
      /** 첨부파일 번호(기타1) */
      attGrpNoOpt1?: string;
      /** 첨부파일 번호(기타2) */
      attGrpNoOpt2?: string;
      /** 첨부파일 번호(기타3) */
      attGrpNoOpt3?: string;
      /** 첨부파일 번호(PC배너) */
      attGrpNoPc?: string;
      /** 첨부파일 번호(썸네일) */
      attGrpNoTn?: string;
      /** 게시글 카테고리 코드 */
      boardCategoryCode: string;
      /** 내용 */
      contents?: string;
      /** 게시종료일 */
      dispEndDate?: string;
      /** 게시시작일 */
      dispStartDate?: string;
      /** 모바일여부 */
      mobileYn?: string;
      /** 뉴스메인여부 */
      newsMainYn?: string;
      /** 옵션데이터1 */
      optData1?: string;
      /** 옵션데이터2 */
      optData2?: string;
      /** 옵션데이터3 */
      optData3?: string;
      /** 옵션데이터4 */
      optData4?: string;
      /** 옵션데이터5 */
      optData5?: string;
      /** 참조URL */
      referUrl?: string;
      /** 등록자ID */
      registerId?: string;
      /** 관련 글 보드 인덱스 */
      relBoardIndexNumber?: string[];
      /** 해시태그 목록 */
      relCategoryCode?: string[];
      /** 보드 상태코드 */
      statusCode?: string;
      /** 제목 */
      subject?: string;
      /** 상단표시 여부 */
      topYn?: string;
      /** 작성자명(회원=로그인명, 비회원=직접입력) */
      writerName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/common/board/insert';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        attGrpNo: params['attGrpNo'],
        attGrpNoMobile: params['attGrpNoMobile'],
        attGrpNoOpt1: params['attGrpNoOpt1'],
        attGrpNoOpt2: params['attGrpNoOpt2'],
        attGrpNoOpt3: params['attGrpNoOpt3'],
        attGrpNoPc: params['attGrpNoPc'],
        attGrpNoTn: params['attGrpNoTn'],
        boardCategoryCode: params['boardCategoryCode'],
        contents: params['contents'],
        dispEndDate: params['dispEndDate'],
        dispStartDate: params['dispStartDate'],
        mobileYn: params['mobileYn'],
        newsMainYn: params['newsMainYn'],
        optData1: params['optData1'],
        optData2: params['optData2'],
        optData3: params['optData3'],
        optData4: params['optData4'],
        optData5: params['optData5'],
        referUrl: params['referUrl'],
        registerId: params['registerId'],
        relBoardIndexNumber: params['relBoardIndexNumber'],
        relCategoryCode: params['relCategoryCode'],
        statusCode: params['statusCode'],
        subject: params['subject'],
        topYn: params['topYn'],
        writerName: params['writerName']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * TB_BOARD 카테고리별 조회 페이징 및 검색조건
   */
  static boListUsingGet(
    params: {
      /** 게시글 카테고리 코드 */
      boardCategoryCode: string[];
      /** 글 번호 */
      boardIndexNumber?: string;
      /** 관련 주제 카테고리 */
      category?: string;
      /** 날짜종류 */
      dateRequirement?: string;
      /** 진행중인 노출상태 조건 */
      displayStatus?: string;
      /** 종료일시 */
      endDate?: string;
      /** 게시시작일 종료(YYYY-MM-DD 형식) */
      endDispStartDate?: string;
      /** 등록일시 종료(YYYY-MM-DD 형식) */
      endFirstRegisterDate?: string;
      /** 이벤트 상태(B:진행중,C:종료) */
      eventStatus?: string;
      /** faq 정렬 옵션 */
      faqOrderOption?: string;
      /** 검색어 */
      keyword?: string;
      /** 검색종류 */
      keywordRequirement?: string;
      /** 옵션데이터1 */
      optData1?: string;
      /** 옵션데이터2 */
      optData2?: string;
      /** 옵션데이터3 */
      optData3?: string;
      /** 옵션데이터4 */
      optData4?: string;
      /** 옵션데이터5 */
      optData5?: string;
      /** 기간만료게시물 표시 여부 */
      outDateDispYn?: string;
      /** 현재 페이지 번호(기본 : 1) */
      page?: number;
      /** 페이지당 보여줄 글 개수(기본 : 10) */
      pagePerCount?: number;
      /** 참조URL 존재 여부 */
      referUrlChkYn?: string;
      /** 등록자ID */
      registerId?: string;
      /** 답변확인여부 */
      replyChkYn?: string;
      /** 답변완료여부 */
      replyOkYn?: string;
      /** 시작일시 */
      startDate?: string;
      /** 게시시작일 시작(YYYY-MM-DD 형식) */
      startDispStartDate?: string;
      /** 등록일시 시작(YYYY-MM-DD 형식) */
      startFirstRegisterDate?: string;
      /** 상태코드 */
      statusCode?: string;
      /** 제목 */
      subject?: string;
      /** 상단표시 여부 */
      topYn?: string;
      /** 모유수유 엄마에게 친근한 일터 정렬 옵션 */
      workplaceOrderOption?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/common/board/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        boardIndexNumber: params['boardIndexNumber'],
        category: params['category'],
        dateRequirement: params['dateRequirement'],
        displayStatus: params['displayStatus'],
        endDate: params['endDate'],
        endDispStartDate: params['endDispStartDate'],
        endFirstRegisterDate: params['endFirstRegisterDate'],
        eventStatus: params['eventStatus'],
        faqOrderOption: params['faqOrderOption'],
        keyword: params['keyword'],
        keywordRequirement: params['keywordRequirement'],
        optData1: params['optData1'],
        optData2: params['optData2'],
        optData3: params['optData3'],
        optData4: params['optData4'],
        optData5: params['optData5'],
        outDateDispYn: params['outDateDispYn'],
        page: params['page'],
        pagePerCount: params['pagePerCount'],
        referUrlChkYn: params['referUrlChkYn'],
        registerId: params['registerId'],
        replyChkYn: params['replyChkYn'],
        replyOkYn: params['replyOkYn'],
        startDate: params['startDate'],
        startDispStartDate: params['startDispStartDate'],
        startFirstRegisterDate: params['startFirstRegisterDate'],
        statusCode: params['statusCode'],
        subject: params['subject'],
        topYn: params['topYn'],
        workplaceOrderOption: params['workplaceOrderOption']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * TB_BOARD 자료실 조회 페이징 및 검색조건
   */
  static boList400UsingGet(
    params: {
      /** 게시글 카테고리 코드 */
      boardCategoryCode: string[];
      /** 글 번호 */
      boardIndexNumber?: string;
      /** 관련 주제 카테고리 */
      category?: string;
      /** 날짜종류 */
      dateRequirement?: string;
      /** 진행중인 노출상태 조건 */
      displayStatus?: string;
      /** 종료일시 */
      endDate?: string;
      /** 게시시작일 종료(YYYY-MM-DD 형식) */
      endDispStartDate?: string;
      /** 등록일시 종료(YYYY-MM-DD 형식) */
      endFirstRegisterDate?: string;
      /** 이벤트 상태(B:진행중,C:종료) */
      eventStatus?: string;
      /** faq 정렬 옵션 */
      faqOrderOption?: string;
      /** 검색어 */
      keyword?: string;
      /** 검색종류 */
      keywordRequirement?: string;
      /** 옵션데이터1 */
      optData1?: string;
      /** 옵션데이터2 */
      optData2?: string;
      /** 옵션데이터3 */
      optData3?: string;
      /** 옵션데이터4 */
      optData4?: string;
      /** 옵션데이터5 */
      optData5?: string;
      /** 기간만료게시물 표시 여부 */
      outDateDispYn?: string;
      /** 현재 페이지 번호(기본 : 1) */
      page?: number;
      /** 페이지당 보여줄 글 개수(기본 : 10) */
      pagePerCount?: number;
      /** 참조URL 존재 여부 */
      referUrlChkYn?: string;
      /** 등록자ID */
      registerId?: string;
      /** 답변확인여부 */
      replyChkYn?: string;
      /** 답변완료여부 */
      replyOkYn?: string;
      /** 시작일시 */
      startDate?: string;
      /** 게시시작일 시작(YYYY-MM-DD 형식) */
      startDispStartDate?: string;
      /** 등록일시 시작(YYYY-MM-DD 형식) */
      startFirstRegisterDate?: string;
      /** 상태코드 */
      statusCode?: string;
      /** 제목 */
      subject?: string;
      /** 상단표시 여부 */
      topYn?: string;
      /** 모유수유 엄마에게 친근한 일터 정렬 옵션 */
      workplaceOrderOption?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/common/board/list400';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        boardIndexNumber: params['boardIndexNumber'],
        category: params['category'],
        dateRequirement: params['dateRequirement'],
        displayStatus: params['displayStatus'],
        endDate: params['endDate'],
        endDispStartDate: params['endDispStartDate'],
        endFirstRegisterDate: params['endFirstRegisterDate'],
        eventStatus: params['eventStatus'],
        faqOrderOption: params['faqOrderOption'],
        keyword: params['keyword'],
        keywordRequirement: params['keywordRequirement'],
        optData1: params['optData1'],
        optData2: params['optData2'],
        optData3: params['optData3'],
        optData4: params['optData4'],
        optData5: params['optData5'],
        outDateDispYn: params['outDateDispYn'],
        page: params['page'],
        pagePerCount: params['pagePerCount'],
        referUrlChkYn: params['referUrlChkYn'],
        registerId: params['registerId'],
        replyChkYn: params['replyChkYn'],
        replyOkYn: params['replyOkYn'],
        startDate: params['startDate'],
        startDispStartDate: params['startDispStartDate'],
        startFirstRegisterDate: params['startFirstRegisterDate'],
        statusCode: params['statusCode'],
        subject: params['subject'],
        topYn: params['topYn'],
        workplaceOrderOption: params['workplaceOrderOption']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 게시글 수정
   */
  static boUpdateUsingPost(
    params: {
      /** 첨부파일 번호(파일) */
      attGrpNo?: string;
      /** 첨부파일 번호(모바일배너) */
      attGrpNoMobile?: string;
      /** 첨부파일 번호(기타1) */
      attGrpNoOpt1?: string;
      /** 첨부파일 번호(기타2) */
      attGrpNoOpt2?: string;
      /** 첨부파일 번호(기타3) */
      attGrpNoOpt3?: string;
      /** 첨부파일 번호(PC배너) */
      attGrpNoPc?: string;
      /** 첨부파일 번호(썸네일) */
      attGrpNoTn?: string;
      /** 게시글 카테고리 코드 */
      boardCategoryCode: string;
      /** 인덱스 번호 */
      boardIndexNumber: string;
      /** 내용 */
      contents?: string;
      /** 게시종료일 */
      dispEndDate?: string;
      /** 게시시작일 */
      dispStartDate?: string;
      /** 모바일여부 */
      mobileYn?: string;
      /** 뉴스메인여부 */
      newsMainYn?: string;
      /** 옵션데이터1 */
      optData1?: string;
      /** 옵션데이터2 */
      optData2?: string;
      /** 옵션데이터3 */
      optData3?: string;
      /** 옵션데이터4 */
      optData4?: string;
      /** 옵션데이터5 */
      optData5?: string;
      /** 참조URL */
      referUrl?: string;
      /** 관련 글 보드 인덱스 */
      relBoardIndexNumber?: string[];
      /** 해시태그 목록 */
      relCategoryCode?: string[];
      /** 보드 상태코드 */
      statusCode?: string;
      /** 제목 */
      subject?: string;
      /** 상단표시 여부 */
      topYn?: string;
      /** 작성자명(회원=로그인명, 비회원=직접입력) */
      writerName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/common/board/update/{boardIndexNumber}';
      url = url.replace('{boardIndexNumber}', params['boardIndexNumber'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        attGrpNo: params['attGrpNo'],
        attGrpNoMobile: params['attGrpNoMobile'],
        attGrpNoOpt1: params['attGrpNoOpt1'],
        attGrpNoOpt2: params['attGrpNoOpt2'],
        attGrpNoOpt3: params['attGrpNoOpt3'],
        attGrpNoPc: params['attGrpNoPc'],
        attGrpNoTn: params['attGrpNoTn'],
        boardCategoryCode: params['boardCategoryCode'],
        contents: params['contents'],
        dispEndDate: params['dispEndDate'],
        dispStartDate: params['dispStartDate'],
        mobileYn: params['mobileYn'],
        newsMainYn: params['newsMainYn'],
        optData1: params['optData1'],
        optData2: params['optData2'],
        optData3: params['optData3'],
        optData4: params['optData4'],
        optData5: params['optData5'],
        referUrl: params['referUrl'],
        relBoardIndexNumber: params['relBoardIndexNumber'],
        relCategoryCode: params['relCategoryCode'],
        statusCode: params['statusCode'],
        subject: params['subject'],
        topYn: params['topYn'],
        writerName: params['writerName']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ebook_viewer
   */
  static ebookViewerUsingGet(
    params: {
      /** ebook명 */
      path: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/ebook/view';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { path: params['path'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * TB_BOARD 카테고리별 전체조회(Build용)
   */
  static foListAllUsingGet(
    params: {
      /** 게시글 카테고리 코드 */
      boardCategoryCode: string[];
      /** 글 번호 */
      boardIndexNumber?: string;
      /** 관련 주제 카테고리 */
      category?: string;
      /** 게시시작일 종료(YYYY-MM-DD 형식) */
      endDispStartDate?: string;
      /** 등록일시 종료(YYYY-MM-DD 형식) */
      endFirstRegisterDate?: string;
      /** 이벤트 상태(B:진행중,C:종료) */
      eventStatus?: string;
      /** faq 정렬 옵션 */
      faqOrderOption?: string;
      /** 옵션데이터1 */
      optData1?: string;
      /** 옵션데이터2 */
      optData2?: string;
      /** 옵션데이터3 */
      optData3?: string;
      /** 옵션데이터4 */
      optData4?: string;
      /** 옵션데이터5 */
      optData5?: string;
      /** 기간만료게시물 표시 여부 */
      outDateDispYn?: string;
      /** 참조URL 존재 여부 */
      referUrlChkYn?: string;
      /** 등록자ID */
      registerId?: string;
      /** 답변확인여부 */
      replyChkYn?: string;
      /** 답변완료여부 */
      replyOkYn?: string;
      /** 게시시작일 시작(YYYY-MM-DD 형식) */
      startDispStartDate?: string;
      /** 등록일시 시작(YYYY-MM-DD 형식) */
      startFirstRegisterDate?: string;
      /** 상태코드 */
      statusCode?: string;
      /** 제목 */
      subject?: string;
      /** 상단표시 여부 */
      topYn?: string;
      /** 모유수유 엄마에게 친근한 일터 정렬 옵션 */
      workplaceOrderOption?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BoardVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/board/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        boardIndexNumber: params['boardIndexNumber'],
        category: params['category'],
        endDispStartDate: params['endDispStartDate'],
        endFirstRegisterDate: params['endFirstRegisterDate'],
        eventStatus: params['eventStatus'],
        faqOrderOption: params['faqOrderOption'],
        optData1: params['optData1'],
        optData2: params['optData2'],
        optData3: params['optData3'],
        optData4: params['optData4'],
        optData5: params['optData5'],
        outDateDispYn: params['outDateDispYn'],
        referUrlChkYn: params['referUrlChkYn'],
        registerId: params['registerId'],
        replyChkYn: params['replyChkYn'],
        replyOkYn: params['replyOkYn'],
        startDispStartDate: params['startDispStartDate'],
        startFirstRegisterDate: params['startFirstRegisterDate'],
        statusCode: params['statusCode'],
        subject: params['subject'],
        topYn: params['topYn'],
        workplaceOrderOption: params['workplaceOrderOption']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * TB_BOARD 카테고리별 전체조회(Build용)
   */
  static all400UsingGet(
    params: {
      /** 게시글 카테고리 코드 */
      boardCategoryCode: string[];
      /** 글 번호 */
      boardIndexNumber?: string;
      /** 관련 주제 카테고리 */
      category?: string;
      /** 게시시작일 종료(YYYY-MM-DD 형식) */
      endDispStartDate?: string;
      /** 등록일시 종료(YYYY-MM-DD 형식) */
      endFirstRegisterDate?: string;
      /** 이벤트 상태(B:진행중,C:종료) */
      eventStatus?: string;
      /** faq 정렬 옵션 */
      faqOrderOption?: string;
      /** 옵션데이터1 */
      optData1?: string;
      /** 옵션데이터2 */
      optData2?: string;
      /** 옵션데이터3 */
      optData3?: string;
      /** 옵션데이터4 */
      optData4?: string;
      /** 옵션데이터5 */
      optData5?: string;
      /** 기간만료게시물 표시 여부 */
      outDateDispYn?: string;
      /** 참조URL 존재 여부 */
      referUrlChkYn?: string;
      /** 등록자ID */
      registerId?: string;
      /** 답변확인여부 */
      replyChkYn?: string;
      /** 답변완료여부 */
      replyOkYn?: string;
      /** 게시시작일 시작(YYYY-MM-DD 형식) */
      startDispStartDate?: string;
      /** 등록일시 시작(YYYY-MM-DD 형식) */
      startFirstRegisterDate?: string;
      /** 상태코드 */
      statusCode?: string;
      /** 제목 */
      subject?: string;
      /** 상단표시 여부 */
      topYn?: string;
      /** 모유수유 엄마에게 친근한 일터 정렬 옵션 */
      workplaceOrderOption?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BoardVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/board/all_400';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        boardIndexNumber: params['boardIndexNumber'],
        category: params['category'],
        endDispStartDate: params['endDispStartDate'],
        endFirstRegisterDate: params['endFirstRegisterDate'],
        eventStatus: params['eventStatus'],
        faqOrderOption: params['faqOrderOption'],
        optData1: params['optData1'],
        optData2: params['optData2'],
        optData3: params['optData3'],
        optData4: params['optData4'],
        optData5: params['optData5'],
        outDateDispYn: params['outDateDispYn'],
        referUrlChkYn: params['referUrlChkYn'],
        registerId: params['registerId'],
        replyChkYn: params['replyChkYn'],
        replyOkYn: params['replyOkYn'],
        startDispStartDate: params['startDispStartDate'],
        startFirstRegisterDate: params['startFirstRegisterDate'],
        statusCode: params['statusCode'],
        subject: params['subject'],
        topYn: params['topYn'],
        workplaceOrderOption: params['workplaceOrderOption']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * TB_BOARD 카테고리별 조회 페이징 및 검색조건
   */
  static foDetailUsingGet(
    params: {
      /** 게시글 카테고리 코드 */
      boardCategoryCode: string;
      /** 글 번호 */
      boardIndexNumber: string;
      /** 검색키워드(제목+내용) */
      keyword?: string;
      /** 등록자ID(카테고리 17번은 로그인 ID로 자동 설정) */
      registerId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/board/detail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        boardIndexNumber: params['boardIndexNumber'],
        keyword: params['keyword'],
        registerId: params['registerId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * TB_BOARD 카테고리별 조회 페이징 및 검색조건
   */
  static foListUsingGet(
    params: {
      /** 게시글 카테고리 코드 */
      boardCategoryCode: string[];
      /** 글 번호 */
      boardIndexNumber?: string;
      /** 관련 주제 카테고리 */
      category?: string[];
      /** 날짜종류 */
      dateRequirement?: string;
      /** 진행중인 노출상태 조건 */
      displayStatus?: string;
      /** 종료일시 */
      endDate?: string;
      /** 게시시작일 종료(YYYY-MM-DD 형식) */
      endDispStartDate?: string;
      /** 등록일시 종료(YYYY-MM-DD 형식) */
      endFirstRegisterDate?: string;
      /** 이벤트 상태(B:진행중,C:종료) */
      eventStatus?: string;
      /** faq 정렬 옵션 */
      faqOrderOption?: string;
      /** 검색어 */
      keyword?: string;
      /** 검색종류 */
      keywordRequirement?: string;
      /** 옵션데이터1 */
      optData1?: string;
      /** 옵션데이터2 */
      optData2?: string;
      /** 옵션데이터3 */
      optData3?: string;
      /** 옵션데이터4 */
      optData4?: string;
      /** 옵션데이터5 */
      optData5?: string;
      /** 기간만료게시물 표시 여부 */
      outDateDispYn?: string;
      /** 현재 페이지 번호(기본 : 1) */
      page?: number;
      /** 페이지당 보여줄 글 개수(기본 : 10) */
      pagePerCount?: number;
      /** 참조URL 존재 여부 */
      referUrlChkYn?: string;
      /** 등록자ID */
      registerId?: string;
      /** 답변확인여부 */
      replyChkYn?: string;
      /** 답변완료여부 */
      replyOkYn?: string;
      /** 시작일시 */
      startDate?: string;
      /** 게시시작일 시작(YYYY-MM-DD 형식) */
      startDispStartDate?: string;
      /** 등록일시 시작(YYYY-MM-DD 형식) */
      startFirstRegisterDate?: string;
      /** 상태코드 */
      statusCode?: string;
      /** 제목 */
      subject?: string;
      /** 상단표시 여부 */
      topYn?: string;
      /** 모유수유 엄마에게 친근한 일터 정렬 옵션 */
      workplaceOrderOption?: string;
      /** 게시글 정렬 옵션 */
      postOrderOption?: string;
      /** 검색키워드 특수문자 사용 여부 (Y일경우 htmlentity 사용 가능) */
      keywordUnescapeHtml?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/board/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        boardIndexNumber: params['boardIndexNumber'],
        category: params['category'],
        dateRequirement: params['dateRequirement'],
        displayStatus: params['displayStatus'],
        endDate: params['endDate'],
        endDispStartDate: params['endDispStartDate'],
        endFirstRegisterDate: params['endFirstRegisterDate'],
        eventStatus: params['eventStatus'],
        faqOrderOption: params['faqOrderOption'],
        keyword: params['keyword'],
        keywordRequirement: params['keywordRequirement'],
        keywordUnescapeHtml: params['keywordUnescapeHtml'],
        optData1: params['optData1'],
        optData2: params['optData2'],
        optData3: params['optData3'],
        optData4: params['optData4'],
        optData5: params['optData5'],
        outDateDispYn: params['outDateDispYn'],
        page: params['page'],
        pagePerCount: params['pagePerCount'],
        referUrlChkYn: params['referUrlChkYn'],
        registerId: params['registerId'],
        replyChkYn: params['replyChkYn'],
        replyOkYn: params['replyOkYn'],
        startDate: params['startDate'],
        startDispStartDate: params['startDispStartDate'],
        startFirstRegisterDate: params['startFirstRegisterDate'],
        statusCode: params['statusCode'],
        subject: params['subject'],
        topYn: params['topYn'],
        workplaceOrderOption: params['workplaceOrderOption'],
        postOrderOption: params['postOrderOption']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * TB_BOARD 카테고리별 조회 페이징 및 검색조건
   */
  static list400UsingGet(
    params: {
      /** 게시글 카테고리 코드 */
      boardCategoryCode: string[];
      /** 글 번호 */
      boardIndexNumber?: string;
      /** 관련 주제 카테고리 */
      category?: string;
      /** 날짜종류 */
      dateRequirement?: string;
      /** 진행중인 노출상태 조건 */
      displayStatus?: string;
      /** 종료일시 */
      endDate?: string;
      /** 게시시작일 종료(YYYY-MM-DD 형식) */
      endDispStartDate?: string;
      /** 등록일시 종료(YYYY-MM-DD 형식) */
      endFirstRegisterDate?: string;
      /** 이벤트 상태(B:진행중,C:종료) */
      eventStatus?: string;
      /** faq 정렬 옵션 */
      faqOrderOption?: string;
      /** 검색어 */
      keyword?: string;
      /** 검색종류 */
      keywordRequirement?: string;
      /** 옵션데이터1 */
      optData1?: string;
      /** 옵션데이터2 */
      optData2?: string;
      /** 옵션데이터3 */
      optData3?: string;
      /** 옵션데이터4 */
      optData4?: string;
      /** 옵션데이터5 */
      optData5?: string;
      /** 기간만료게시물 표시 여부 */
      outDateDispYn?: string;
      /** 현재 페이지 번호(기본 : 1) */
      page?: number;
      /** 페이지당 보여줄 글 개수(기본 : 10) */
      pagePerCount?: number;
      /** 참조URL 존재 여부 */
      referUrlChkYn?: string;
      /** 등록자ID */
      registerId?: string;
      /** 답변확인여부 */
      replyChkYn?: string;
      /** 답변완료여부 */
      replyOkYn?: string;
      /** 시작일시 */
      startDate?: string;
      /** 게시시작일 시작(YYYY-MM-DD 형식) */
      startDispStartDate?: string;
      /** 등록일시 시작(YYYY-MM-DD 형식) */
      startFirstRegisterDate?: string;
      /** 상태코드 */
      statusCode?: string;
      /** 제목 */
      subject?: string;
      /** 상단표시 여부 */
      topYn?: string;
      /** 모유수유 엄마에게 친근한 일터 정렬 옵션 */
      workplaceOrderOption?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/board/list_400';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        boardIndexNumber: params['boardIndexNumber'],
        category: params['category'],
        dateRequirement: params['dateRequirement'],
        displayStatus: params['displayStatus'],
        endDate: params['endDate'],
        endDispStartDate: params['endDispStartDate'],
        endFirstRegisterDate: params['endFirstRegisterDate'],
        eventStatus: params['eventStatus'],
        faqOrderOption: params['faqOrderOption'],
        keyword: params['keyword'],
        keywordRequirement: params['keywordRequirement'],
        optData1: params['optData1'],
        optData2: params['optData2'],
        optData3: params['optData3'],
        optData4: params['optData4'],
        optData5: params['optData5'],
        outDateDispYn: params['outDateDispYn'],
        page: params['page'],
        pagePerCount: params['pagePerCount'],
        referUrlChkYn: params['referUrlChkYn'],
        registerId: params['registerId'],
        replyChkYn: params['replyChkYn'],
        replyOkYn: params['replyOkYn'],
        startDate: params['startDate'],
        startDispStartDate: params['startDispStartDate'],
        startFirstRegisterDate: params['startFirstRegisterDate'],
        statusCode: params['statusCode'],
        subject: params['subject'],
        topYn: params['topYn'],
        workplaceOrderOption: params['workplaceOrderOption']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * TB_BOARD 카테고리별 전체조회(Build용)
   */
  static top3400UsingGet(
    params: {
      /** 게시글 카테고리 코드 */
      boardCategoryCode: string[];
      /** 글 번호 */
      boardIndexNumber?: string;
      /** 관련 주제 카테고리 */
      category?: string;
      /** 게시시작일 종료(YYYY-MM-DD 형식) */
      endDispStartDate?: string;
      /** 등록일시 종료(YYYY-MM-DD 형식) */
      endFirstRegisterDate?: string;
      /** 이벤트 상태(B:진행중,C:종료) */
      eventStatus?: string;
      /** faq 정렬 옵션 */
      faqOrderOption?: string;
      /** 옵션데이터1 */
      optData1?: string;
      /** 옵션데이터2 */
      optData2?: string;
      /** 옵션데이터3 */
      optData3?: string;
      /** 옵션데이터4 */
      optData4?: string;
      /** 옵션데이터5 */
      optData5?: string;
      /** 기간만료게시물 표시 여부 */
      outDateDispYn?: string;
      /** 참조URL 존재 여부 */
      referUrlChkYn?: string;
      /** 등록자ID */
      registerId?: string;
      /** 답변확인여부 */
      replyChkYn?: string;
      /** 답변완료여부 */
      replyOkYn?: string;
      /** 게시시작일 시작(YYYY-MM-DD 형식) */
      startDispStartDate?: string;
      /** 등록일시 시작(YYYY-MM-DD 형식) */
      startFirstRegisterDate?: string;
      /** 상태코드 */
      statusCode?: string;
      /** 제목 */
      subject?: string;
      /** 상단표시 여부 */
      topYn?: string;
      /** 모유수유 엄마에게 친근한 일터 정렬 옵션 */
      workplaceOrderOption?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BoardVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/board/top3_400';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        boardIndexNumber: params['boardIndexNumber'],
        category: params['category'],
        endDispStartDate: params['endDispStartDate'],
        endFirstRegisterDate: params['endFirstRegisterDate'],
        eventStatus: params['eventStatus'],
        faqOrderOption: params['faqOrderOption'],
        optData1: params['optData1'],
        optData2: params['optData2'],
        optData3: params['optData3'],
        optData4: params['optData4'],
        optData5: params['optData5'],
        outDateDispYn: params['outDateDispYn'],
        referUrlChkYn: params['referUrlChkYn'],
        registerId: params['registerId'],
        replyChkYn: params['replyChkYn'],
        replyOkYn: params['replyOkYn'],
        startDispStartDate: params['startDispStartDate'],
        startFirstRegisterDate: params['startFirstRegisterDate'],
        statusCode: params['statusCode'],
        subject: params['subject'],
        topYn: params['topYn'],
        workplaceOrderOption: params['workplaceOrderOption']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * TB_BOARD 조회수 증가
   */
  static viewCntUsingPost(
    params: {
      /** 글 번호(BOARD_INDEX_NUMBER) */
      boardIndexNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/board/viewcount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { boardIndexNumber: params['boardIndexNumber'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * igreport
   */
  static igreportUsingGet(
    params: {
      /** Track Code */
      trackCode: string;
      /** 연도 */
      year: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/igreport/{year}/{trackCode}';
      url = url.replace('{trackCode}', params['trackCode'] + '');
      url = url.replace('{year}', params['year'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BoExcelDownloadControllerService {
  /**
   * 관리자 > 작업 이력 다운로드
   */
  static adminHistoryListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/adminHistory/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 관리자 > 계정 관리 다운로드
   */
  static adminUserListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/adminUser/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * [푸른 하늘의 날 랜딩] 신청자 목록 다운로드
   */
  static blueSkyApplyListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/blueSky/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 공통보드 엑셀 다운로드
   */
  static boardListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/board/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * [달력 그림 공모전] 접수 목록 다운로드
   */
  static calendarApplyListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/calendarDrawing/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * [새해다짐 챌린지] 신청자 목록 다운로드
   */
  static challengeListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/challenge/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 행사·서명 신청자 목록 다운로드
   */
  static eventModuleApplyListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** moduleCode */
      moduleCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/event/module/list/{moduleCode}';
      url = url.replace('{moduleCode}', params['moduleCode'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * [온라인 필드트립] 신청자 목록 다운로드
   */
  static fieldtripListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/fieldtrip/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 1:1문의 목록 엑셀 다운로드
   */
  static oneToOneListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/inquiry/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * [특별 후원 문의] 다운로드
   */
  static legacyQnaListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/legacy/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 접속이력 엑셀 다운로드
   */
  static accessListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/loginAccess/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 웹 회원 관리 엑셀 다운로드
   */
  static webMemberListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/member/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * partyForUnicef 후기 엑셀 다운로드
   */
  static partyForUnicefEndListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/partyForUnicef/end-list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * partyForUnicef 신청자 엑셀 다운로드
   */
  static partyForUnicefListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/partyForUnicef/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 우편신청 엑셀 다운로드
   */
  static publicationApplyListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/publication-apply/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 자원봉사 신청자 엑셀 다운로드
   */
  static volunteerApplyListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
      /** volunteerIndexNumber */
      volunteerIndexNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/volunteer/list/{volunteerIndexNumber}';
      url = url.replace('{volunteerIndexNumber}', params['volunteerIndexNumber'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * WLL 신청자 목록 다운로드
   */
  static wllApplyListUsingGet(
    params: {
      /** 보드카테고리코드 */
      boardCategoryCode: string[];
      /** 다운로드 사유 */
      downloadReason: string;
      /** 종료일 */
      endDate: string;
      /** 관리자 메뉴코드 */
      menuCode: string;
      /** 시작일 */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/bo/excel/wll/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        boardCategoryCode: params['boardCategoryCode'],
        downloadReason: params['downloadReason'],
        endDate: params['endDate'],
        menuCode: params['menuCode'],
        startDate: params['startDate']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AttFileControllerService {
  /**
   * 공통  > 첨부 파일 다운
   */
  static downloadUsingGet(
    params: {
      /** 업로드 경로(암호화 처리 BASE64) */
      attUploadPath: string;
      /** 표시파일명 */
      dispFileNm: string;
      /** 서버파일명 */
      svrFileNm: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InputStreamResource> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/download';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        attUploadPath: params['attUploadPath'],
        dispFileNm: params['dispFileNm'],
        svrFileNm: params['svrFileNm']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 공통  > 첨부 파일 다운
   */
  static downloadIndexnumberUsingGet(
    params: {
      /** 첨부IDX */
      indexNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InputStreamResource> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/download/{indexNumber}';
      url = url.replace('{indexNumber}', params['indexNumber'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 공통  > 첨부 파일 다운
   */
  static downloadOneAttGrpNoUsingGet(
    params: {
      /** 첨부그룹번호 */
      attGrpNo: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InputStreamResource> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/downloadOne/{attGrpNo}';
      url = url.replace('{attGrpNo}', params['attGrpNo'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 공통  > 첨부파일 1건 조회
   */
  static fileOneAttGrpNoUsingGet(
    params: {
      /** 첨부그룹번호 */
      attGrpNo: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Result2> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/file';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { attGrpNo: params['attGrpNo'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 첨부파일 삭제
   */
  static fileDelUsingPost(
    params: {
      /** 첨부IDX */
      indexNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/file/del/{indexNumber}';
      url = url.replace('{indexNumber}', params['indexNumber'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 첨부파일 삭제
   */
  static fileDel2UsingPost(
    params: {
      /** 첨부파일번호 */
      attGrpNo: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/file/delete/{attGrpNo}';
      url = url.replace('{attGrpNo}', params['attGrpNo'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 공통  > 첨부목록 조회
   */
  static listUsingGet15(
    params: {
      /** 첨부그룹번호 */
      attGrpNo: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Result2[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/filelist';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { attGrpNo: params['attGrpNo'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 공통  > 첨부 이미지 보기
   */
  static imgViewUsingGet(
    params: {
      /** 이미지경로 */
      imgPath: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/img/view';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { imgPath: params['imgPath'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 공통  > 첨부 이미지 보기
   */
  static imgViewAttGrpNoUsingGet1(
    params: {
      /** 첨부IDX */
      attGrpNo: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/img/view/grpNo/{attGrpNo}';
      url = url.replace('{attGrpNo}', params['attGrpNo'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 공통  > 첨부 이미지 보기
   */
  static imgViewAttGrpNoUsingGet1_(
    params: {
      /** 첨부IDX */
      attGrpNo: string;
      /** 옵션(사이즈 등) */
      optData1: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/img/view/grpNo/{attGrpNo}/{optData1}';
      url = url.replace('{attGrpNo}', params['attGrpNo'] + '');
      url = url.replace('{optData1}', params['optData1'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 공통  > 첨부 이미지 보기
   */
  static imgViewIndexnumberUsingGet(
    params: {
      /** 첨부IDX */
      indexNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/img/view/no/{indexNumber}';
      url = url.replace('{indexNumber}', params['indexNumber'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * img_view2
   */
  static imgView2UsingGet(
    params: {
      /** 이미지경로 */
      imgPath: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/img/view2';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { imgPath: params['imgPath'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 파일업로드
   */
  static uploadUsingPost(
    params: {
      /** 첨부그룹번호 */
      attGrpNo?: string;
      /** 첨부파일 */
      file: any[];
      /** 옵션데이터1 */
      optData1?: string[];
      /** 옵션데이터2 */
      optData2?: string[];
      /** 옵션데이터3 */
      optData3?: string[];
      /** 옵션데이터4 */
      optData4?: string[];
      /** 옵션데이터5 */
      optData5?: string[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Result1> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = {
        attGrpNo: params['attGrpNo'],
        optData1: params['optData1'],
        optData2: params['optData2'],
        optData3: params['optData3'],
        optData4: params['optData4'],
        optData5: params['optData5']
      };
      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 에디터 이미지 업로드
   */
  static uploadEditorImageUsingPost(
    params: {
      /** 첨부파일 */
      upload: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/upload_editor_image';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['upload']) {
        if (Object.prototype.toString.call(params['upload']) === '[object Array]') {
          for (const item of params['upload']) {
            data.append('upload', item as any);
          }
        } else {
          data.append('upload', params['upload'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 파일업로드 상품 이미지
   */
  static uploadProductUsingPost(
    params: {
      /** 첨부그룹번호 */
      attGrpNo?: string;
      /** 첨부파일 */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Result1> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/attachFile/upload_product_img';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = { attGrpNo: params['attGrpNo'] };
      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CheckPlusSafeControllerService {
  /**
   * NICE 입력 정보 생성
   */
  static setNiceEncDataUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/nice/enc';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 본인인증 실패
   */
  static checkPlusFailUsingGet(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/nice/fail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 본인인증 실패
   */
  static checkPlusFailUsingPost(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/nice/fail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 본인인증 성공
   */
  static checkPlusSuccessUsingGet(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/nice/success';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 본인인증 성공
   */
  static checkPlusSuccessUsingPost(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/nice/success';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PageMgrControllerService {
  /**
   * 페이지 관리 문구 목록(Build용)
   */
  static listUsingGet16(options: IRequestOptions = {}): Promise<PageMgrVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/pageMgr/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 페이지 관리 문구 수정
   */
  static updateValueUsingPost(
    params: {
      /** id */
      id: string;
      /** Path url */
      path: string;
      /** value */
      value: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PageMgrVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/common/pageMgr/updateValue';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'], path: params['path'], value: params['value'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AccountCheckControllerService {
  /**
   * 이니시스 계좌인증 Iframe 호출용
   */
  static accountCheckUsingGet(
    params: {
      /** model */
      model?: object[];
      /**  */
      strErrMsg?: string;
      /**  */
      strRet?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/accountCheck';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { model: params['model'], strErrMsg: params['strErrMsg'], strRet: params['strRet'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 로그인 사용자 정보
   */
  static getMemberInfo1UsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/getMemberInfo1';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 비밀번호 인증 설정
   */
  static setPasswordCheckUsingGet( 
    params: {
    /** 비밀번호 */
    password: string;
  } = {} as any,
  options: IRequestOptions = {}
  ) : Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/setPasswordCheck';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { password: params['password'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    })
  }
  /**
   * SNS 인증 설정
   */
  static setSnsAuthCheckUsingGet( 
    params: {
    /** 비밀번호 */
    socialId: string,
    snsDiv: string;
  } = {} as any,
  options: IRequestOptions = {}
  ) : Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/setSnsAuthCheck';
      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.data = { ... params };
      axios(configs, resolve, reject);
    })
  }

  /**
   * 비밀번호 인증 확인(1분이내)
   */
  static getPasswordCheckUsingGet(options: IRequestOptions = {}) : Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/getPasswordCheck';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject)
    })
  }
}

export class AddressCommonControllerService {
  /**
   * 주소 검색 공통 컨트롤러(시/군/구)별 검색
   */
  static getAllCityCodeUsingGet(options: IRequestOptions = {}): Promise<AddressVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/address/city';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class NameCheckControllerService {
  /**
   * siren 실명인증 팝업 or Iframe 호출용
   */
  static certNameUsingGet(
    params: {
      /**  */
      jumin?: string;
      /** model */
      model?: object[];
      /**  */
      name?: string;
      /**  */
      phoneNumber?: string;
      /**  */
      reqInfo?: string;
      /**  */
      returnUrl?: string;
      /**  */
      srvNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/certName';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        jumin: params['jumin'],
        model: params['model'],
        name: params['name'],
        phoneNumber: params['phoneNumber'],
        reqInfo: params['reqInfo'],
        returnUrl: params['returnUrl'],
        srvNo: params['srvNo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 암호화 데이터 만드는 메소드
   */
  static sirenNameAuthUsingGet(
    params: {
      /**  */
      jumin?: string;
      /**  */
      name?: string;
      /**  */
      phoneNumber?: string;
      /**  */
      reqInfo?: string;
      /**  */
      returnUrl?: string;
      /**  */
      srvNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SirenAuthVO> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        jumin: params['jumin'],
        name: params['name'],
        phoneNumber: params['phoneNumber'],
        reqInfo: params['reqInfo'],
        returnUrl: params['returnUrl'],
        srvNo: params['srvNo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 암호화 데이터 만드는 메소드
   */
  static sirenNameAuthUsingHead(
    params: {
      /**  */
      jumin?: string;
      /**  */
      name?: string;
      /**  */
      phoneNumber?: string;
      /**  */
      reqInfo?: string;
      /**  */
      returnUrl?: string;
      /**  */
      srvNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SirenAuthVO> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuth';

      const configs: IRequestConfig = getConfigs('head', 'application/json', url, options);
      configs.params = {
        jumin: params['jumin'],
        name: params['name'],
        phoneNumber: params['phoneNumber'],
        reqInfo: params['reqInfo'],
        returnUrl: params['returnUrl'],
        srvNo: params['srvNo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 암호화 데이터 만드는 메소드
   */
  static sirenNameAuthUsingPost(
    params: {
      /**  */
      jumin?: string;
      /**  */
      name?: string;
      /**  */
      phoneNumber?: string;
      /**  */
      reqInfo?: string;
      /**  */
      returnUrl?: string;
      /**  */
      srvNo?: string;
    } = {} as any,
    encKey: {
      key: string;
      cert: string;
      expiry?: string;
      isSecureContext?: boolean;
    },
    options: IRequestOptions = {}
  ): Promise<SirenAuthVO> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuth';
      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.data = {
        jumin: params.jumin,
        name: params.name,
        phoneNumber: params['phoneNumber'],
        reqInfo: params['reqInfo'],
        returnUrl: params['returnUrl'],
        srvNo: params['srvNo'],
        encCert: encKey.cert,
        publicKey: encKey.key,
        secureContextYn: encKey.isSecureContext ? 'Y' : 'N',
      };
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 암호화 데이터 만드는 메소드
   */
  static sirenNameAuthUsingPut(
    params: {
      /**  */
      jumin?: string;
      /**  */
      name?: string;
      /**  */
      phoneNumber?: string;
      /**  */
      reqInfo?: string;
      /**  */
      returnUrl?: string;
      /**  */
      srvNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SirenAuthVO> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuth';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = {
        jumin: params['jumin'],
        name: params['name'],
        phoneNumber: params['phoneNumber'],
        reqInfo: params['reqInfo'],
        returnUrl: params['returnUrl'],
        srvNo: params['srvNo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 암호화 데이터 만드는 메소드
   */
  static sirenNameAuthUsingDelete(
    params: {
      /**  */
      jumin?: string;
      /**  */
      name?: string;
      /**  */
      phoneNumber?: string;
      /**  */
      reqInfo?: string;
      /**  */
      returnUrl?: string;
      /**  */
      srvNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SirenAuthVO> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuth';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = {
        jumin: params['jumin'],
        name: params['name'],
        phoneNumber: params['phoneNumber'],
        reqInfo: params['reqInfo'],
        returnUrl: params['returnUrl'],
        srvNo: params['srvNo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 암호화 데이터 만드는 메소드
   */
  static sirenNameAuthUsingOptions(
    params: {
      /**  */
      jumin?: string;
      /**  */
      name?: string;
      /**  */
      phoneNumber?: string;
      /**  */
      reqInfo?: string;
      /**  */
      returnUrl?: string;
      /**  */
      srvNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SirenAuthVO> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuth';

      const configs: IRequestConfig = getConfigs('options', 'application/json', url, options);
      configs.params = {
        jumin: params['jumin'],
        name: params['name'],
        phoneNumber: params['phoneNumber'],
        reqInfo: params['reqInfo'],
        returnUrl: params['returnUrl'],
        srvNo: params['srvNo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 암호화 데이터 만드는 메소드
   */
  static sirenNameAuthUsingPatch(
    params: {
      /**  */
      jumin?: string;
      /**  */
      name?: string;
      /**  */
      phoneNumber?: string;
      /**  */
      reqInfo?: string;
      /**  */
      returnUrl?: string;
      /**  */
      srvNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SirenAuthVO> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuth';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);
      configs.params = {
        jumin: params['jumin'],
        name: params['name'],
        phoneNumber: params['phoneNumber'],
        reqInfo: params['reqInfo'],
        returnUrl: params['returnUrl'],
        srvNo: params['srvNo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 결과값을 받는 메소드 결과값 : 1(정상), 다른 숫자(올바르지 않은 이름,주민번호)
   */
  static sirenNameAuthResultUsingGet(
    params: {
      /**  */
      ciVersion?: string;
      /**  */
      ciscrHash?: string;
      /**  */
      discrHash?: string;
      /**  */
      name?: string;
      /**  */
      result?: string;
      /**  */
      retInfo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuthResult';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ciVersion: params['ciVersion'],
        ciscrHash: params['ciscrHash'],
        discrHash: params['discrHash'],
        name: params['name'],
        result: params['result'],
        retInfo: params['retInfo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 결과값을 받는 메소드 결과값 : 1(정상), 다른 숫자(올바르지 않은 이름,주민번호)
   */
  static sirenNameAuthResultUsingHead(
    params: {
      /**  */
      ciVersion?: string;
      /**  */
      ciscrHash?: string;
      /**  */
      discrHash?: string;
      /**  */
      name?: string;
      /**  */
      result?: string;
      /**  */
      retInfo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuthResult';

      const configs: IRequestConfig = getConfigs('head', 'application/json', url, options);
      configs.params = {
        ciVersion: params['ciVersion'],
        ciscrHash: params['ciscrHash'],
        discrHash: params['discrHash'],
        name: params['name'],
        result: params['result'],
        retInfo: params['retInfo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 결과값을 받는 메소드 결과값 : 1(정상), 다른 숫자(올바르지 않은 이름,주민번호)
   */
  static sirenNameAuthResultUsingPost(
    params: {
      /**  */
      ciVersion?: string;
      /**  */
      ciscrHash?: string;
      /**  */
      discrHash?: string;
      /**  */
      name?: string;
      /**  */
      result?: string;
      /**  */
      retInfo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuthResult';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        ciVersion: params['ciVersion'],
        ciscrHash: params['ciscrHash'],
        discrHash: params['discrHash'],
        name: params['name'],
        result: params['result'],
        retInfo: params['retInfo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 결과값을 받는 메소드 결과값 : 1(정상), 다른 숫자(올바르지 않은 이름,주민번호)
   */
  static sirenNameAuthResultUsingPut(
    params: {
      /**  */
      ciVersion?: string;
      /**  */
      ciscrHash?: string;
      /**  */
      discrHash?: string;
      /**  */
      name?: string;
      /**  */
      result?: string;
      /**  */
      retInfo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuthResult';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = {
        ciVersion: params['ciVersion'],
        ciscrHash: params['ciscrHash'],
        discrHash: params['discrHash'],
        name: params['name'],
        result: params['result'],
        retInfo: params['retInfo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 결과값을 받는 메소드 결과값 : 1(정상), 다른 숫자(올바르지 않은 이름,주민번호)
   */
  static sirenNameAuthResultUsingDelete(
    params: {
      /**  */
      ciVersion?: string;
      /**  */
      ciscrHash?: string;
      /**  */
      discrHash?: string;
      /**  */
      name?: string;
      /**  */
      result?: string;
      /**  */
      retInfo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuthResult';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = {
        ciVersion: params['ciVersion'],
        ciscrHash: params['ciscrHash'],
        discrHash: params['discrHash'],
        name: params['name'],
        result: params['result'],
        retInfo: params['retInfo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 결과값을 받는 메소드 결과값 : 1(정상), 다른 숫자(올바르지 않은 이름,주민번호)
   */
  static sirenNameAuthResultUsingOptions(
    params: {
      /**  */
      ciVersion?: string;
      /**  */
      ciscrHash?: string;
      /**  */
      discrHash?: string;
      /**  */
      name?: string;
      /**  */
      result?: string;
      /**  */
      retInfo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuthResult';

      const configs: IRequestConfig = getConfigs('options', 'application/json', url, options);
      configs.params = {
        ciVersion: params['ciVersion'],
        ciscrHash: params['ciscrHash'],
        discrHash: params['discrHash'],
        name: params['name'],
        result: params['result'],
        retInfo: params['retInfo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Siren 실명인증 결과값을 받는 메소드 결과값 : 1(정상), 다른 숫자(올바르지 않은 이름,주민번호)
   */
  static sirenNameAuthResultUsingPatch(
    params: {
      /**  */
      ciVersion?: string;
      /**  */
      ciscrHash?: string;
      /**  */
      discrHash?: string;
      /**  */
      name?: string;
      /**  */
      result?: string;
      /**  */
      retInfo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/sirenNameAuthResult';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);
      configs.params = {
        ciVersion: params['ciVersion'],
        ciscrHash: params['ciscrHash'],
        discrHash: params['discrHash'],
        name: params['name'],
        result: params['result'],
        retInfo: params['retInfo']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  /**
   * Siren 실명인증 암호화 키 생성
  */
  static generateSirenNameCryptoKey(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/generateSirenNameCryptoKey';
      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.data = null;
      axios(configs, resolve, reject);
    });
  }

}

export class EmailRefuseControllerService {
  /**
   * 이메일 수신거부 처리
   */
  static emailRefuseProcUsingPost(
    params: {
      /** 수신거부할 이메일 주소 */
      email: string;
      /** 수신거부코드 값(1,2,3,4,5(기타 직접입력) */
      refuseReasonCode: string;
      /** 기타직접 입력내용 (수신거부코드 값 = '5') 일 경우 직접 입력내용을 담아주세요 */
      refuseReasonDetail?: string;
      /** 트랙코드, URL에 트랙코드가 존재시 담아서 보내주세요. */
      trackCode?: string;
      /** 트랙코드 ID(숫자값), URL에 트랙코드ID가 존재시 담아서 보내주세요. */
      trackId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/email/refuse';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        email: params['email'],
        refuseReasonCode: params['refuseReasonCode'],
        refuseReasonDetail: params['refuseReasonDetail'],
        trackCode: params['trackCode'],
        trackId: params['trackId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MaillingCommonControllerService {
  /**
   * 메일 발송 [테스트용]
   */
  static sendMailTestUsingPost(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/mail/sendMail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PaymentControllerService {
  /**
   * 주문번호로 결제완료된 IG 정보를 얻을 수 있는 API (결제 완료 페이지 사용)
   */
  static getIgInfoUsingGet(
    params: {
      /** 주문번호 */
      orderNumber: string;
      /** 난수값 */
      orderToken: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IgInfoVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/payment/getIgInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderNumber: params['orderNumber'], orderToken: params['orderToken'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 주문번호로 결제 정보를 얻을 수 있는 API (결제 완료 페이지 사용)
   */
  static getPaymentInfoUsingGet(
    params: {
      /** 주문번호 */
      orderNumber: string;
      /** 난수값 */
      orderToken: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentMasterInfoVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/payment/getPaymentInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderNumber: params['orderNumber'], orderToken: params['orderToken'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 주문번호로 결제 정보를 얻을 수 있는 API (결제 완료 페이지 사용)
   */
  static getPaymentInfoUsingPost(
    params: {
      /** 주문번호 */
      orderNumber: string;
      /** 난수값 */
      orderToken: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentMasterInfoVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/payment/getPaymentInfo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { orderNumber: params['orderNumber'], orderToken: params['orderToken'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 이니시스 결제창 취소시 받는 URL
   */
  static inicisLayerCancleUsingGet(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/payment/inicisLayerCancle';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 이니시스 모바일 폼
   */
  static inicisMobileFormUsingPost(
    params: {
      /** 결제금액 */
      amt: number;
      /** 상품명 */
      goods: string;
      /** 휴대폰결제 상품유형[1:컨텐츠, 2:실물] */
      hppMethod?: string;
      /** 요청지불수단 */
      iniPayment: string;
      /** 상점아이디 */
      mid: string;
      /** model */
      model?: object[];
      /** 결과수신URL(자동 세팅 되어있음) */
      nextUrl?: string;
      /** 가상계좌입금통보URL(가상계좌 결제 시 필수) */
      notiUrl?: string;
      /** 주문번호 */
      oid: string;
      /** 구매자명 */
      uname: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/payment/inicisMobileForm';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        amt: params['amt'],
        goods: params['goods'],
        hppMethod: params['hppMethod'],
        iniPayment: params['iniPayment'],
        mid: params['mid'],
        model: params['model'],
        nextUrl: params['nextUrl'],
        notiUrl: params['notiUrl'],
        oid: params['oid'],
        uname: params['uname']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 이니시스 PC 폼
   */
  static inicisPcFormUsingPost(
    params: {
      /** acceptmethod */
      acceptmethod: string;
      /** 구매자 이메일주소 */
      buyeremail: string;
      /** 구매자명 */
      buyername: string;
      /** 구매자 휴대폰 번호 */
      buyertel: string;
      /** 상품명 */
      goodname: string;
      /** 요청지불수단 */
      gopaymethod: string;
      /** 상점아이디 */
      mid: string;
      /** SHA256 Hash값 [대상: mid 와 매칭되는 signkey] */
      mkey: string;
      /** model */
      model?: object[];
      /** 주문번호 */
      oid: string;
      /** 상품가격 */
      price: number;
      /** SHA256 Hash값 [대상: oid, price, timestamp] */
      signature: string;
      /** 타임스템프 */
      timestamp: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/payment/inicisPcForm';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        acceptmethod: params['acceptmethod'],
        buyeremail: params['buyeremail'],
        buyername: params['buyername'],
        buyertel: params['buyertel'],
        goodname: params['goodname'],
        gopaymethod: params['gopaymethod'],
        mid: params['mid'],
        mkey: params['mkey'],
        model: params['model'],
        oid: params['oid'],
        price: params['price'],
        signature: params['signature'],
        timestamp: params['timestamp']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 이니시스 결제창 취소시 받는 URL
   */
  static kgLayerCancelUsingGet(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/payment/kgLayerCancel';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 이니시스 PC 폼
   */
  static kgPaymentFormUsingPost(
    params: {
      /** 상품명 */
      goodname: string;
      /** model */
      model?: object[];
      /** 주문번호 */
      oid: string;
      /** 상품가격 */
      price: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/payment/kgPaymentForm';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        goodname: params['goodname'],
        model: params['model'],
        oid: params['oid'],
        price: params['price']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 결제 정보를 입력하고 주문번호를 발급, 카드 유효성 확인, 해외카드 결제(일시결제) API
   */
  static paymentInfoSetUsingPost(
    params: {
      /** ACS 타입(ARS, sign) */
      acsType?: string;
      /** 주소 */
      address?: string;
      /** 주소상세 */
      addressDetail?: string;
      /** 기념일('-'제외 / yyyyMMdd) */
      anniDate?: string;
      /** 기념일 종류 */
      anniType?: string;
      /** 은행코드 */
      banksett?: string;
      /** 생년월일(yyyyMMdd) */
      birthday?: string;
      /** 사업자번호('-'제외) */
      businessNumber?: string;
      /** 카드유효기간 (이니시스 일시결제시 필수, yyMM) */
      cardExpire?: string;
      /** 카드번호 (이니시스 일시결제시 필수, '-' 제외) */
      cardNumber?: string;
      /** 회사/단체명 */
      companyName?: string;
      /** 담당자 연락처('-'제외) */
      contact?: string;
      /** 예금주 생년월일 8자리(yyyyMMdd) */
      depositorBirthday?: string;
      /** 예금주 휴대전화번호('-'제외) */
      depositorMobilePhoneNumber?: string;
      /** 접근기기 타입(PC, MOBILE) / 대문자 */
      deviceType: string;
      /** 후원상품코드  (세계어린이지원(일시) : D201, 긴급구호 : DS01, 기념기부 : D224, IG(생명을 구하는 선물) : D302) */
      donationCode: string;
      /** 이메일(개인/법인) */
      email: string;
      /** 이메일 동의여부(true : Y, false : N) */
      emailAgreeYn: string;
      /** 주민등록번호('-'제외) */
      juminNumber?: string;
      /** 담당자 이메일 */
      managerEmail?: string;
      /** 담당자명 */
      managerName?: string;
      /** 후원자명 */
      memberName?: string;
      /** 후원자타입코드(IN:개인, IB:개인사업자, CO:기업, ORE:기타단체, OR:기타법인, CL:동호회, HO:병원, FN:재단, RE:종교단체, SCH:학교, GV:정부, OR : 기타) */
      memberTypeCode: string;
      /** 모바일 동의여부(true : Y, false : N) */
      mobileAgreeYn: string;
      /** 휴대전화번호('-'제외) */
      mobilePhoneNumber: string;
      /** 후원 모듈 코드 (관리자 페이지에서 관리하는 모듈 코드) */
      moduleCode?: string;
      /** 국가코드(KR : 대한민국, EN : 영어권 | 국내결제, 해외결제 구분용) */
      nationCode: string;
      /** 예금주명 */
      nmcomp?: string;
      /** 계좌번호('-'제외) */
      noacct?: string;
      /** 일반결제 / IG(생명을 구하는 선물) 결제 구분용 파라미터(일반결제 : null or SUPPORT, 생명을 구하는 선물 : IG) */
      pathType: string;
      /** (정기)결제일 */
      paymentDay?: string;
      /** 결제타입코드  (자동이체 : FA04, 신용카드 : FA05/FG05/FG11, 실시간계좌이체 : FG10, 휴대폰 : FG07, 무통장 입금 : FG06, 네이버페이-정기 : FANP/FGNP, 네이버페이-일시 : FGNO) */
      paymentTypeCode: string;
      /** 우편 동의여부(true : Y, false : N) */
      postAgreeYn: string;
      /** IG 상품코드(여러개는 쉼표(,)로 구분함 / 최대 50개) */
      productCode?: string;
      /** IG 상품수량(장바구니 담지 않고 1개 상품 주문할때만 사용) */
      productCount?: number;
      /** 상품명(후원명) */
      productName: string;
      /** 예금주와의 관계 */
      relation?: string;
      /** 법정대리인 이름 */
      relationName?: string;
      /** 법정대리인 전화번호('-'제외) */
      relationTelNumber?: string;
      /** 법정대리인 관계 */
      relationType?: string;
      /** 서명값 바이너리 */
      signBinary?: string;
      /** 캠페인 코드 */
      solicitCode?: string;
      /** 사연 */
      story?: string;
      /** 결제 완료 후 호출될 redirectUrl */
      successUrl?: string;
      /** 후원종류코드  (정기 : FM01, 일시 : FM02) */
      supportTypeCode: string;
      /** 총 결제금액(최소값 100) */
      totalAmount: number;
      /** TRACK_CODE */
      trackcode?: string;
      /** TRACK_ID */
      trkId?: string;
      /** 우편번호 */
      zipCode?: string;
      /** 개인정보 암호화 : 인증정보 */
      encCert?: string;
      /** 개인정보 암호화 : 키 */
      publicKey?: string;
      /** 개인정보 암호화 : 현금영수증 신청여부 */
      agreeReceiptYn?: string;
      /** 개인정보 암호화 : 보안컨텍스트 여부 */
      secureContextYn?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentInfoSetVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/payment/paymentInfoSet';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params=null;
      configs.data = {
        acsType: params['acsType'],
        address: params['address'],
        addressDetail: params['addressDetail'],
        anniDate: params['anniDate'],
        anniType: params['anniType'],
        banksett: params['banksett'],
        birthday: params['birthday'],
        businessNumber: params['businessNumber'],
        cardExpire: params['cardExpire'],
        cardNumber: params['cardNumber'],
        companyName: params['companyName'],
        contact: params['contact'],
        depositorBirthday: params['depositorBirthday'],
        depositorMobilePhoneNumber: params['depositorMobilePhoneNumber'],
        deviceType: params['deviceType'],
        donationCode: params['donationCode'],
        email: params['email'],
        emailAgreeYn: params['emailAgreeYn'],
        juminNumber: params['juminNumber'],
        managerEmail: params['managerEmail'],
        managerName: params['managerName'],
        memberName: params['memberName'],
        memberTypeCode: params['memberTypeCode'],
        mobileAgreeYn: params['mobileAgreeYn'],
        mobilePhoneNumber: params['mobilePhoneNumber'],
        moduleCode: params['moduleCode'],
        nationCode: params['nationCode'],
        nmcomp: params['nmcomp'],
        noacct: params['noacct'],
        pathType: params['pathType'],
        paymentDay: params['paymentDay'],
        paymentTypeCode: params['paymentTypeCode'],
        postAgreeYn: params['postAgreeYn'],
        productCode: params['productCode'],
        productCount: params['productCount'],
        productName: params['productName'],
        relation: params['relation'],
        relationName: params['relationName'],
        relationTelNumber: params['relationTelNumber'],
        relationType: params['relationType'],
        signBinary: params['signBinary'],
        solicitCode: params['solicitCode'],
        story: params['story'],
        successUrl: params['successUrl'],
        supportTypeCode: params['supportTypeCode'],
        totalAmount: params['totalAmount'],
        trackcode: params['trackcode'],
        trk_id: params['trkId'],
        zipCode: params['zipCode'],
        encCert: params['encCert'],
        publicKey: params['publicKey'],
        agreeReceiptYn: params['agreeReceiptYn'],
        secureContextYn: params['secureContextYn'],
      };
      axios(configs, resolve, reject);
    });
  }
  /**
   * 해외카드 결제
   */
  static paymentInfoSetEnUsingPost(
    params: {
      /** 카드유효기간 (이니시스 일시결제시 필수, yyMM) */
      cardExpire: string;
      /** 카드번호 (이니시스 일시결제시 필수, '-' 제외) */
      cardNumber: string;
      /** 개발 해외 결제용 카드소지자 생년월일 */
      depositorBirthday?: string;
      /** 접근기기 타입(PC, MOBILE) / 대문자 */
      deviceType: string;
      /** 이메일(개인/법인) */
      email: string;
      /** 후원자명 */
      memberName: string;
      /** 후원 모듈 코드 (관리자 페이지에서 관리하는 모듈 코드) */
      moduleCode?: string;
      /** 캠페인 코드 */
      solicitCode?: string;
      /** 총 결제금액(최소값 100) */
      totalAmount: number;
      /** TRACK_CODE */
      trackcode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentInfoSetVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/payment/paymentInfoSetEn';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        cardExpire: params['cardExpire'],
        cardNumber: params['cardNumber'],
        depositorBirthday: params['depositorBirthday'],
        deviceType: params['deviceType'],
        email: params['email'],
        memberName: params['memberName'],
        moduleCode: params['moduleCode'],
        solicitCode: params['solicitCode'],
        totalAmount: params['totalAmount'],
        trackcode: params['trackcode']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Gift ID로 결제 API - 놓친 후원
   */
  static paymentInfoSetGiftIdUsingPost(
    params: {
      /** 접근기기 타입(PC, MOBILE) / 대문자 */
      deviceType: string;
      /** 납부월일 */
      giftAidDate: string;
      /** GIFT ID */
      giftId: string;
      /** 결제타입코드  (자동이체 : FA04, 신용카드 : FA05/FG05/FG11, 실시간계좌이체 : FG10, 휴대폰 : FG07, 무통장 입금 : FG06, 네이버페이-정기 : FANP/FGNP, 네이버페이-일시 : FGNO) */
      paymentTypeCode: string;
      /** 결제 완료 후 호출될 redirectUrl */
      successUrl?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/payment/paymentInfoSetGiftId';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        deviceType: params['deviceType'],
        giftAidDate: params['giftAidDate'],
        giftId: params['giftId'],
        paymentTypeCode: params['paymentTypeCode'],
        successUrl: params['successUrl']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * NaverPay 정기 네이버엔 정상 등록되었는데, 기간계 등록에 실패한 경우
   */
  static naverRegularReturnUsingGet1(
    params: {
      /** orderNumber */
      orderNumber: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/payment/retryNaverRegularReturn';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderNumber: params['orderNumber'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SupportModuleControllerService {
  /**
   * 학교 후원 활동내역 등록
   */
  static addSchoolDonationUsingPost(
    params: {
      /** 이미지첨부번호 */
      attGrpNoActive?: string;
      /** 활동내용 */
      content: string;
      /** gift번호 */
      giftId: string;
      /** 영수증 신청여부 */
      reqTax?: string;
      /** 감사장 신청여부 */
      reqThx?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/addSchoolDonation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        attGrpNoActive: params['attGrpNoActive'],
        content: params['content'],
        giftId: params['giftId'],
        reqTax: params['reqTax'],
        reqThx: params['reqThx']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원모듈 목록 전체 조회(jkKim)
   */
  static getSupportModuleListAllUsingGet(options: IRequestOptions = {}): Promise<SupportModuleListVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 회원가입 완료 후 자동 후원내역 연결
   */
  static setAutoConnectionByJoinUserIdUsingPost(
    params: {
      /** 생년월일 ( 구분'개인' : 생년월일 앞자리 8자리 (yyyymmdd), 회원가입 당시 생년월일(yyyymmdd) */
      birthOrBizNumber: string;
      /** 핸드폰번호 (01012345678), 회원가입 당시 핸드폰번호 */
      mobilePhoneNumber: string;
      /** 이름, 회원가입 당시 이름 */
      name: string;
      /** 후원인 구분( A : 개인후원자 ) */
      supportGubun: string;
      /** 사용자 ID(회원가입 완료 한 ID), 회원가입 당시 사용자ID */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/auto/connection';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        birthOrBizNumber: params['birthOrBizNumber'],
        mobilePhoneNumber: params['mobilePhoneNumber'],
        name: params['name'],
        supportGubun: params['supportGubun'],
        userId: params['userId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 자동이체 가능한 은행 목록 조회 API
   */
  static getBankListUsingGet(options: IRequestOptions = {}): Promise<BankVo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/bankList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원자명, 주민번호로 후원정보를 가져오는 API
   */
  static checkSupportInfoUsingGet(
    params: {
      /** 주민등록번호 / 사업자번호('-' 자동제외) */
      juminNumberDec: string;
      /** 후원자명, 단체명 */
      memberName: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupportInfoResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/checkSupportInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { juminNumberDec: params['juminNumberDec'], memberName: params['memberName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 내 후원내역 연결하기 - 후원연결 [ Step 2 ]
   */
  static setConnectionByUserInfoUsingPost(
    params: {
      /** 생년월일 ( 구분'개인' : 생년월일 앞자리 8자리 (yyyymmdd), 구분'사업자' : 사업자번호(숫자만) */
      birthOrBizNumber: string;
      /** 핸드폰번호 (01012345678) */
      mobilePhoneNumber: string;
      /** 이름 */
      name: string;
      /** SMS 인증번호(사업자 또는 법인 인 경우 필수입니다.) */
      smsAuthCode?: string;
      /** 후원인 구분(A : 개인후원자, B : 사업자 또는 법인, C :그 밖의 단체) */
      supportGubun: string;
      /** 사용자 ID, 입력하지 않아도 됩니다. */
      userId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/connection';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        birthOrBizNumber: params['birthOrBizNumber'],
        mobilePhoneNumber: params['mobilePhoneNumber'],
        name: params['name'],
        smsAuthCode: params['smsAuthCode'],
        supportGubun: params['supportGubun'],
        userId: params['userId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 내 후원내역 연결하기 - 후원연결 아이디 존재 유무 및 후원이력 존재여부 파악 [ Step 1 ]
   */
  static setConnectionCheckUsingGet(
    params: {
      /** 생년월일 ( 구분'개인' : 생년월일 앞자리 8자리 (yyyymmdd), 구분'사업자' : 사업자번호(숫자만) */
      birthOrBizNumber: string;
      /** 핸드폰번호 (01012345678) */
      mobilePhoneNumber: string;
      /** 이름 */
      name: string;
      /** SMS 인증번호(사업자 또는 법인 인 경우 필수입니다.) */
      smsAuthCode?: string;
      /** 후원인 구분(A : 개인후원자, B : 사업자 또는 법인, C :그 밖의 단체) */
      supportGubun: string;
      /** 사용자 ID, 입력하지 않아도 됩니다. */
      userId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/connection/check';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        birthOrBizNumber: params['birthOrBizNumber'],
        mobilePhoneNumber: params['mobilePhoneNumber'],
        name: params['name'],
        smsAuthCode: params['smsAuthCode'],
        supportGubun: params['supportGubun'],
        userId: params['userId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원자 웹ID로 후원자관리시스템의 정보를 가지고 오는 API
   */
  static dpoMemberInfoUsingGet(
    params: {
      /** 후원자 웹ID */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DpoMemberInfoVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/dpoMemberInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원모듈정보를 가져오는 API
   */
  static getEventModuleInfoUsingGet(
    params: {
      /** 화면코드  요청 URL */
      displayUrl: string;
      /** 미리보기(상태에 상관 없이 조회 필요한 경우) */
      preview?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/getEventModuleInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { displayUrl: params['displayUrl'], preview: params['preview'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원유형코드정보를 가져오는 API
   */
  static getFoSupportCodeUsingGet(
    params: {
      /** 후원타입 */
      categoryCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/getFoSupportCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { categoryCode: params['categoryCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
  * 후원코드 후원금액 조회
  */
  static getSupportCodeAmount(options: IRequestOptions = {}) : Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/amount/detail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
    
      let data = null;
    
      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  
  /**
   * 후원 모듈에서 저장한 정보 가져오기
   */
  static getSupportModuleInputInfoUsingGet(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/getSupportModuleInputInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원자 donorId 값으로 정기후원 최신결제정보를 가져오는 API
   */
  static regularPayInfoUsingGet(
    params: {
      /** 후원자 고유번호 */
      donorId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/regularPayInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { donorId: params['donorId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 이벤트서명 모듈에서 저장한 값.
   */
  static setEventModuleInfoUsingPost(
    params: {
      /** param */
      param: object[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/setEventModuleInfo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { param: params['param'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원 모듈에서 설정한 값 전달
   */
  static setSupportModuleInfoUsingGet(
    params: {
      /** param */
      param: object[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/setSupportModuleInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { param: params['param'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원모듈정보를 가져오는 API
   */
  static supportModuleInfoUsingGet(
    params: {
      /** 화면코드  요청 URL */
      displayUrl: string;
      /** 미리보기(상태에 상관 없이 조회 필요한 경우) */
      preview?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/supportModuleInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { displayUrl: params['displayUrl'], preview: params['preview'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 후원모듈정보를 가져오는 API
   */
  static supportModuleInfo2UsingGet(
    params: {
      /** 후원모듈코드 */
      moduleCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/supportModuleInfo/{moduleCode}';
      url = url.replace('{moduleCode}', params['moduleCode'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 정기 후원 완료 시 만족도 조사 링크 조회(kmBae)
   */
  static getSatisfactionSurveyLinkUsingGet(
    params: {
      /** 후원번호 */
      donorId: string;
      /** gift번호 */
      giftId: string;
      /**  */
      inx62Out?: string;
      /**  */
      linkEncOut?: string;
      /** 핸드폰번호(01012345678) */
      mobilePhoneNumber: string;
      /** 이름 */
      name: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/survey/link';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        donorId: params['donorId'],
        giftId: params['giftId'],
        inx62Out: params['inx62Out'],
        linkEncOut: params['linkEncOut'],
        mobilePhoneNumber: params['mobilePhoneNumber'],
        name: params['name']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 기념기부 프로젝트 > 기념일종류 가져오는 API
   */
   static getFoAnniTypeCodeUsingGet(
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/getFoAnniTypeCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * gtm > 캠페인명 가져오는 API
   */
  static getCampaignNameUsingGet(
    params: {
      /** 캠페인 코드 */
      solicitCode?: string;
    } = {} as any,
    options: IRequestConfig = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/support/getCampaignName';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        solicitCode: params['solicitCode'],
      }
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject)
    })
  }
}

export class UnicefTrkControllerService {
  /**
   * unicef_trk 입력
   */
  static insertUnicefTrkDataUsingPost(
    params: {
      /** map */
      map: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UnicefTrkDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/common/unicef_trk';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['map'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MemberInfoControllerService {
  /**
   * 로그인 사용자 정보 조회(이름,연락처,이메일..)
   */
  static memberLoginInfoUsingGet(
    params: {
      /** map */
      myInfoToken?: string | null;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      const path = location.pathname;
      let url = basePath + '/unicef/api/fo/member/info/';

      // 개인정보 비번인증
      if (path === '/mypage/my-info' && params.myInfoToken) {
        url += 'my/';
        options.headers['X-AUTH-MY'] = params.myInfoToken;
      };

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MemberJoinControllerService {
  /**
   * 이메일 인증 확인
   */
  static emailAuthCheckUsingPost(
    params: {
      /** 요청한 이메일 주소 */
      email: string;
      /** 이메일 인증코드 */
      emailAuthCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/member/join/auth/email';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { email: params['email'], emailAuthCode: params['emailAuthCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 이메일 인증코드 발급
   */
  static emailAuthCodeUsingGet(
    params: {
      /** 이메일 인증코드를 사용처 ( 회원가입('join') or 아아디/패스워드 찾기 ('find') ) 구분 */
      emailAuthUseType: string;
      /** 이메일 인증코드 발급받을 메일주소 */
      reciveMailAddr: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/member/join/auth/email/authCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { emailAuthUseType: params['emailAuthUseType'], reciveMailAddr: params['reciveMailAddr'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 회원 삭제( 개발자 용도 )
   */
  static deleteUserUsingPost(
    params: {
      /** 단건('userId1') 복수건('userid1,userid2,userid3') 콤마로 구분 */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/member/join/deleteUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Email 중복 확인
   */
  static duplicateCheckUserEmailUsingGet(
    params: {
      /** 사용자 Email */
      email: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/member/join/duplicate/email';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ID중복 확인
   */
  static duplicateCheckUserIdUsingGet(
    params: {
      /** 사용자ID */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/member/join/duplicate/id';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 패스워드 변경(패스워드 찾기 후 변경)
   */
  static setChangePasswordByFindUserInfoUsingGet(
    params: {
      /** 이메일, 이메일로 인증한경우 필수 */
      email?: string;
      /** 핸드폰번호, 핸드폰번호로 인증한경우 필수 */
      mobilePhoneNumber?: string;
      /** 패스워드 */
      password: string;
      /** 사용자 ID */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/member/join/find/changePassword';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        email: params['email'],
        mobilePhoneNumber: params['mobilePhoneNumber'],
        password: params['password'],
        userId: params['userId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ID찾기(핸드폰인증, 이메일 인증)
   */
  static findMyIdUsingGet(
    params: {
      /** 인증타입(핸드폰본인인증 : P, 이메일 인증 : M) */
      authType: string;
      /** 이메일 */
      email?: string;
      /** 이메일 인증코드(이메일 인증시 필수) */
      emailAuthCode?: string;
      /** 핸드폰번호 */
      mobilePhoneNumber?: string;
      /** 이름 */
      name: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/member/join/find/id';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        authType: params['authType'],
        email: params['email'],
        emailAuthCode: params['emailAuthCode'],
        mobilePhoneNumber: params['mobilePhoneNumber'],
        name: params['name']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 패스워드 찾기(핸드폰인증, 이메일 인증)
   */
  static getFindMyPasswordUsingGet(
    params: {
      /** 인증타입(핸드폰본인인증 : P, 이메일 인증 : M) */
      authType: string;
      /** 이메일 */
      email?: string;
      /** 이메일 인증코드(이메일 인증시 필수) */
      emailAuthCode?: string;
      /** 핸드폰번호 */
      mobilePhoneNumber?: string;
      /** 이름 */
      name: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/member/join/find/password';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        authType: params['authType'],
        email: params['email'],
        emailAuthCode: params['emailAuthCode'],
        mobilePhoneNumber: params['mobilePhoneNumber'],
        name: params['name']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 일반 사용자 회원가입
   */
  static memberJoinUsingPost(
    params: {
      /** 인증타입(핸드폰본인인증 : P, 이메일 인증 : M) */
      authType: string;
      /** 생년월일 (yyyymmdd), 일반사용자의 경우 필수!, 간편가입시 단체의 경우에는 값 없어도 됩니다. */
      birthday?: string;
      /** 후원자번호, 간편가입시 후원자번호 입력 필수! (후원내역 연결에 사용) */
      donorId?: number;
      /** 이메일 */
      email: string;
      /** 모바일 정보수신 동의 */
      emailAgreeYn?: string;
      /** 이메일 인증 코드(인증타입이 이메일인증(M)) 인 경우 필수 */
      emailAuthCode?: string;
      /** 핸드폰번호 */
      mobilePhoneNumber?: string;
      /** 성명/단체명 */
      name: string;
      /** 패스워드 */
      password: string;
      /** 패스워드New, 값 넣지 않아도 됩니다 */
      passwordNew?: string;
      /** 작성자 IP, 값 넣지 않아도 됩니다 */
      registerIp?: string;
      /** 법정대리인과의 관계(만 14세이하의 유저의 경우 수집) */
      relation?: string;
      /** 법정대리인명(만 14세이하의 유저의 경우 수집) */
      relationName?: string;
      /** 법정대리인 휴대폰번호(만 14세이하의 유저의 경우 수집) */
      relationTelNumber?: string;
      /** 간편회원가입 여부(Y,N), 기본값(N) */
      simepleMemberJoin: string;
      /** 이메일 정보수신 동의 */
      smsAgreeYn?: string;
      /** 사용자 ID */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/member/join/proc';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        authType: params['authType'],
        birthday: params['birthday'],
        donorId: params['donorId'],
        email: params['email'],
        emailAgreeYn: params['emailAgreeYn'],
        emailAuthCode: params['emailAuthCode'],
        mobilePhoneNumber: params['mobilePhoneNumber'],
        name: params['name'],
        password: params['password'],
        passwordNew: params['passwordNew'],
        registerIp: params['registerIp'],
        relation: params['relation'],
        relationName: params['relationName'],
        relationTelNumber: params['relationTelNumber'],
        simepleMemberJoin: params['simepleMemberJoin'],
        smsAgreeYn: params['smsAgreeYn'],
        userId: params['userId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 간편회원가입 사용자 정보 중복 확인(return : 중복이 아닐 경우 'success', 중복 일 경우 'failed')
   */
  static simpleDuplicateCheckInfoUsingGet(
    params: {
      /** 결제자 생년월일(yyyymmdd) */
      birthday: string;
      /** 결제자 이메일 */
      email: string;
      /** 결제자 핸드폰번호(01012345678) */
      mobilePhoneNumber: string;
      /** 결제자 이름 */
      name: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/member/join/simple/duplicate/info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        birthday: params['birthday'],
        email: params['email'],
        mobilePhoneNumber: params['mobilePhoneNumber'],
        name: params['name']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MemberLoginControllerService {
  /**
   * 사용자 로그인
   */
  static memberLoginUsingPost(
    params: {
      /** 사용자 ID */
      id: string;
      /** 비밀번호 */
      password: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/member/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'], password: params['password'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 사용자 로그아웃
   */
  static userLogoutUsingPost(
    params: {
      /** 사용자 ID */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/member/logout';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * JWT Access 토큰 갱신
   */
  static refreshTokenUsingPost1(options: IRequestOptions = {}): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/member/refresh';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SocialJoinControllerService {
  /**
   * 소셜 회원가입
   */
  static socialJoinUsingPost(
    params: {
      /** 생년월일 */
      birthday: string;
      /** 이메일 */
      email: string;
      /** 모바일 정보수신 동의 */
      emailAgreeYn?: string;
      /** 중복가입 진행여부 (이미 가입된 메일인 경우 판단 [ N : 기본값, Y : 일 경우 중복된 메일이 있더라도 회원가입을 진행) */
      existEamilPassYn: string;
      /** 핸드폰번호 */
      mobilePhoneNumber?: string;
      /** 성명/단체명 */
      name: string;
      /** 패스워드, 값 넣지 않아도 됩니다 */
      password?: string;
      /** 패스워드New, 값 넣지 않아도 됩니다 */
      passwordNew?: string;
      /** 작성자 IP, 값 넣지 않아도 됩니다 */
      registerIp?: string;
      /** 법정대리인과의 관계(만 14세이하의 유저의 경우 수집) */
      relation?: string;
      /** 법정대리인명(만 14세이하의 유저의 경우 수집) */
      relationName?: string;
      /** 법정대리인 휴대폰번호(만 14세이하의 유저의 경우 수집) */
      relationTelNumber?: string;
      /** 이메일 정보수신 동의 */
      smsAgreeYn?: string;
      /** 소셜고유ID */
      socialId: string;
      /** 소셜구분('N', 'G', 'F') */
      socialType: string;
      /** 사용자ID, 값 넣지 않아도 됩니다 */
      userId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/social/join';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        birthday: params['birthday'],
        email: params['email'],
        emailAgreeYn: params['emailAgreeYn'],
        existEamilPassYn: params['existEamilPassYn'],
        mobilePhoneNumber: params['mobilePhoneNumber'],
        name: params['name'],
        password: params['password'],
        passwordNew: params['passwordNew'],
        registerIp: params['registerIp'],
        relation: params['relation'],
        relationName: params['relationName'],
        relationTelNumber: params['relationTelNumber'],
        smsAgreeYn: params['smsAgreeYn'],
        socialId: params['socialId'],
        socialType: params['socialType'],
        userId: params['userId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 소셜 회원가입 전 해당 이메일로 중복가입되었는지, 이미 회원가입한 소셜회원인지 판단
   */
  static socialJoinBeforeEmailDuplicateCheckUsingPost(
    params: {
      /** 소셜로그인한 이메일 주소 */
      email: string;
      /** 소셜 고유ID */
      id: string;
      /** SNS구분('N' :네이버, 'G' : 구글, 'F' 페이스북) */
      snsDiv: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/social/join/check';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { email: params['email'], id: params['id'], snsDiv: params['snsDiv'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SocialLoginControllerService {
  /**
   * 소셜(네이버,페이스북,구글) 로그인
   */
  static naverLoginUsingGet(
    params: {
      /** 네이버/페이스북 인증(AccessToken), 구글인증 (idToken) */
      accessToken: string;
      /** 소셜 타입('naver','google','facebook') */
      social: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultVo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/unicef/api/fo/social/{social}/login';
      url = url.replace('{social}', params['social'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { accessToken: params['accessToken'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface AddressVo {
  /**  */
  cityName?: string;

  /**  */
  gunName?: string;

  /**  */
  indexNumber?: string;
}

export interface AttFileVo {}

export interface BankVo {
  /**  */
  code?: string;

  /**  */
  description?: string;
}

export interface BoardVo {
  /**  */
  arrAttFile?: AttFileVo[];

  /**  */
  arrRelBoard?: string[];

  /**  */
  attGrpNo?: string;

  /**  */
  attGrpNoMobile?: string;

  /**  */
  attGrpNoOpt1?: string;

  /**  */
  attGrpNoOpt2?: string;

  /**  */
  attGrpNoOpt3?: string;

  /**  */
  attGrpNoPc?: string;

  /**  */
  attGrpNoTn?: string;

  /**  */
  boardCategoryCode?: string;

  /**  */
  boardIndexNumber?: string;

  /**  */
  codeName?: string;

  /**  */
  contents?: string;

  /**  */
  deleteYn?: string;

  /**  */
  dispEndDate?: string;

  /**  */
  dispStartDate?: string;

  /**  */
  eventEndDate?: string;

  /**  */
  eventStartDate?: string;

  /**  */
  eventStatus?: string;

  /**  */
  eventStatusName?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  firstRegisterFullDate?: string;

  /**  */
  imgAttGrpNo?: string;

  /**  */
  imgAttGrpNoMobile?: string;

  /**  */
  imgAttGrpNoOpt1?: string;

  /**  */
  imgAttGrpNoOpt2?: string;

  /**  */
  imgAttGrpNoOpt3?: string;

  /**  */
  imgAttGrpNoPc?: string;

  /**  */
  imgAttGrpNoTn?: string;

  /**  */
  lastModifyDate?: string;

  /**  */
  mobileYn?: string;

  /**  */
  newsMainYn?: string;

  /**  */
  nextBoardIndexNumber?: string;

  /**  */
  nextRegDate?: string;

  /**  */
  nextSubject?: string;

  /**  */
  optData1?: string;

  /**  */
  optData2?: string;

  /**  */
  optData3?: string;

  /**  */
  optData4?: string;

  /**  */
  optData5?: string;

  /**  */
  prevBoardIndexNumber?: string;

  /**  */
  prevRegDate?: string;

  /**  */
  prevSubject?: string;

  /**  */
  referUrl?: string;

  /**  */
  registerId?: string;

  /**  */
  relBoardIndexNumber?: string;

  /**  */
  relCategoryCode?: string;

  /**  */
  replyChkYn?: string;

  /**  */
  replyOkYn?: string;

  /**  */
  rowNum?: number;

  /**  */
  statusCode?: string;

  /**  */
  subject?: string;

  /**  */
  topYn?: string;

  /**  */
  viewCnt?: string;

  /**  */
  writerName?: string;
}

export interface CommonResultVo {
  /**  */
  data?: object;

  /**  */
  resultCode?: string;

  /**  */
  resultMessage?: string;

  /**  */
  totalCount?: number;
}

export interface ComponentInfosVo {
  /**  */
  id?: string;

  /**  */
  type?: string;

  /**  */
  value?: string;
}

export interface DpoMemberInfoVo {
  /**  */
  address?: string;

  /**  */
  addressDetail?: string;

  /**  */
  birthday?: string;

  /**  */
  businessNumber?: string;

  /**  */
  businessPhoneNumber?: string;

  /**  */
  donorId?: string;

  /**  */
  dpoBirthday?: string;

  /**  */
  dpoBusinessPhoneNumber?: string;

  /**  */
  dpoMemberName?: string;

  /**  */
  dpoMobilePhoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  emailAgreeYn?: string;

  /**  */
  existYn?: string;

  /**  */
  juminNumber?: string;

  /**  */
  managerName?: string;

  /**  */
  memberId?: string;

  /**  */
  memberName?: string;

  /**  */
  memberTypeCode?: string;

  /**  */
  mobileAgreeYn?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  postAgreeYn?: string;

  /** 응답코드(성공 : success, 실패 : failed) */
  resultCode?: string;

  /** 응답 메세지 */
  resultMessage?: string;

  /**  */
  supportHistoryExistYn?: string;

  /**  */
  userId?: string;

  /**  */
  zipCode?: string;
}

export interface File {
  /**  */
  absolute?: boolean;

  /**  */
  absoluteFile?: File;

  /**  */
  absolutePath?: string;

  /**  */
  canonicalFile?: File;

  /**  */
  canonicalPath?: string;

  /**  */
  directory?: boolean;

  /**  */
  file?: boolean;

  /**  */
  freeSpace?: number;

  /**  */
  hidden?: boolean;

  /**  */
  name?: string;

  /**  */
  parent?: string;

  /**  */
  parentFile?: File;

  /**  */
  path?: string;

  /**  */
  totalSpace?: number;

  /**  */
  usableSpace?: number;
}

export interface IgInfoVo {
  /**  */
  imgProductAttImgNo1_140?: string;

  /**  */
  imgProductAttImgNo1_290?: string;

  /**  */
  imgProductAttImgNo1_420?: string;

  /**  */
  imgProductAttImgNo1_50?: string;

  /**  */
  productAttImgNo1?: string;

  /**  */
  productAttImgNo2?: string;

  /**  */
  productAttImgNo3?: string;

  /**  */
  productAttImgNo4?: string;

  /**  */
  productCode?: string;

  /**  */
  productCount?: string;

  /**  */
  productName?: string;

  /**  */
  productNameEn?: string;

  /**  */
  productPrice?: string;
}

export interface InputStream {}

export interface InputStreamResource {
  /**  */
  description?: string;

  /**  */
  file?: File;

  /**  */
  filename?: string;

  /**  */
  inputStream?: InputStream;

  /**  */
  open?: boolean;

  /**  */
  readable?: boolean;

  /**  */
  uri?: URI;

  /**  */
  url?: URL;
}

export interface PageMgrVo {
  /**  */
  componentInfos?: ComponentInfosVo[];

  /**  */
  path?: string;

  /**  */
  pathName?: string;
}

export interface PaymentInfoSetVo {
  /** 빌키 */
  billKey?: string;

  /**  */
  mkey?: string;

  /** 주문번호 */
  orderNumber?: string;

  /** 보안용 난수 */
  orderToken?: string;

  /** 이전URL */
  prevUrl?: string;

  /** 네이버 정기결제 reserveId */
  reserveId?: string;

  /** 응답코드(성공 : success, 실패 : failed) */
  resultCode?: string;

  /** 응답 메세지 */
  resultMessage?: string;

  /** 이니시스 PC signature */
  signature?: string;

  /** 이니시스 PC timestamp */
  timestamp?: string;
}

export interface PaymentMasterInfoVo {
  /**  */
  acctName?: string;

  /**  */
  acsType?: string;

  /**  */
  actionType?: string;

  /**  */
  address?: string;

  /**  */
  addressDetail?: string;

  /**  */
  admissionState?: string;

  /**  */
  admissionTypeCode?: string;

  /**  */
  admissionYmdt?: string;

  /**  */
  appTime?: string;

  /**  */
  applNum?: string;

  /**  */
  authToken?: string;

  /**  */
  authUrl?: string;

  /**  */
  bankAccountNo?: string;

  /**  */
  bankCorpCode?: string;

  /**  */
  banksett?: string;

  /**  */
  banksettName?: string;

  /**  */
  basketUserId?: string;

  /**  */
  billKey?: string;

  /**  */
  billKeyTid?: string;

  /**  */
  birthday?: string;

  /**  */
  businessNumber?: string;

  /**  */
  cardAuthNo?: string;

  /**  */
  cardCode?: string;

  /**  */
  cardCorpCode?: string;

  /**  */
  cardExpire?: string;

  /**  */
  cardInstCount?: number;

  /**  */
  cardKind?: string;

  /**  */
  cardNo?: string;

  /**  */
  cardNumber?: string;

  /**  */
  checkFlg?: string;

  /**  */
  clientIp?: string;

  /**  */
  companyName?: string;

  /**  */
  contact?: string;

  /**  */
  depositorBirthday?: string;

  /**  */
  depositorMobilePhoneNumber?: string;

  /**  */
  deviceType?: string;

  /**  */
  donationCode?: string;

  /**  */
  donorId?: string;

  /**  */
  email?: string;

  /**  */
  emailAgreeYn?: string;

  /**  */
  extraDeduction?: boolean;

  /**  */
  firstRegisterDate?: string;

  /**  */
  giftAidDate?: string;

  /**  */
  giftId?: string;

  /**  */
  gopaymethod?: string;

  /**  */
  hashData?: string;

  /**  */
  hppNum?: string;

  /**  */
  inicisAcctBankName?: string;

  /**  */
  inicisCardName?: string;

  /**  */
  inicisCardNumber?: string;

  /**  */
  inicisVactBankName?: string;

  /**  */
  juminNumber?: string;

  /**  */
  mailDm?: string;

  /**  */
  mailEdm?: string;

  /**  */
  mailSms?: string;

  /**  */
  managerEmail?: string;

  /**  */
  managerName?: string;

  /**  */
  memberName?: string;

  /**  */
  memberTypeCode?: string;

  /**  */
  mobileAgreeYn?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  moduleCode?: string;

  /**  */
  naverBankName?: string;

  /**  */
  naverCardName?: string;

  /**  */
  netCancelUrl?: string;

  /**  */
  nmcomp?: string;

  /**  */
  noacct?: string;

  /**  */
  oldPayDay?: string;

  /**  */
  oldPaymentType?: string;

  /**  */
  orderNumber?: string;

  /**  */
  orderToken?: string;

  /**  */
  pathType?: string;

  /**  */
  pauthDt?: string;

  /**  */
  pauthNo?: string;

  /**  */
  payCommissionAmount?: number;

  /**  */
  payDate?: string;

  /**  */
  payHistId?: string;

  /**  */
  payName?: string;

  /**  */
  payNo?: string;

  /**  */
  payTime?: string;

  /**  */
  paymentDay?: string;

  /**  */
  paymentId?: string;

  /**  */
  paymentSuccessYn?: string;

  /**  */
  paymentTypeCode?: string;

  /**  */
  paymentTypeName?: string;

  /**  */
  phppMethod?: string;

  /**  */
  piniPayment?: string;

  /**  */
  pnextUrl?: string;

  /**  */
  pnotiUrl?: string;

  /**  */
  postAgreeYn?: string;

  /**  */
  preRecurrentId?: string;

  /**  */
  primaryPayMeans?: string;

  /**  */
  productCode?: string;

  /**  */
  productCount?: string;

  /**  */
  productName?: string;

  /**  */
  ptid?: string;

  /**  */
  recurrentId?: string;

  /**  */
  regCd?: string;

  /**  */
  regNo?: string;

  /**  */
  relation?: string;

  /**  */
  relationName?: string;

  /**  */
  relationTelNumber?: string;

  /**  */
  relationType?: string;

  /**  */
  reserveId?: string;

  /**  */
  returnAccountNumber?: string;

  /**  */
  returnBankName?: string;

  /**  */
  returnCardName?: string;

  /**  */
  returnCardNumber?: string;

  /**  */
  rewordYn?: string;

  /**  */
  sessionId?: string;

  /**  */
  settleExpectAmount?: number;

  /**  */
  settleExpected?: boolean;

  /**  */
  signature?: string;

  /**  */
  solicitCode?: string;

  /**  */
  startDate?: string;

  /**  */
  successUrl?: string;

  /**  */
  supportTypeCode?: string;

  /**  */
  tempReceiptId?: string;

  /**  */
  tid?: string;

  /**  */
  timestamp?: string;

  /**  */
  totalAmount?: number;

  /**  */
  trackId?: string;

  /**  */
  trackcode?: string;

  /**  */
  tradeConfirmYmdt?: string;

  /**  */
  url?: string;

  /**  */
  useCfmYmdt?: string;

  /**  */
  usedCardPoint?: boolean;

  /**  */
  userId?: string;

  /**  */
  vactDate?: string;

  /**  */
  vactName?: string;

  /**  */
  vactNum?: string;

  /**  */
  zipCode?: string;
}

export interface Result1 {
  /**  */
  attGrpNo?: string;
}

export interface Result2 {
  /**  */
  attFileSize?: string;

  /**  */
  attGrpNo?: string;

  /**  */
  attUploadPath?: string;

  /**  */
  deleteYn?: string;

  /**  */
  dispFileNm?: string;

  /**  */
  downloadUrl?: string;

  /**  */
  firstRegisterDate?: string;

  /**  */
  imgUrl?: string;

  /**  */
  indexNumber?: number;

  /**  */
  lastModifyDate?: string;

  /**  */
  optData1?: string;

  /**  */
  optData2?: string;

  /**  */
  optData3?: string;

  /**  */
  optData4?: string;

  /**  */
  optData5?: string;

  /**  */
  registerId?: string;

  /**  */
  svrFileNm?: string;
}

export interface SirenAuthVO {
  /**  */
  actionUrl?: string;

  /**  */
  reqInfo?: string;

  /**  */
  retUrl?: string;
}

export interface SupportCodeVo {
  /**  */
  categoryCode?: string;

  /**  */
  categoryCodeName?: string;

  /**  */
  emergencyUseYn?: string;

  /**  */
  relIndexNumber?: number;

  /** 응답코드(성공 : success, 실패 : failed) */
  resultCode?: string;

  /** 응답 메세지 */
  resultMessage?: string;

  /**  */
  supportCode?: string;

  /**  */
  supportTitle?: string;
}

export interface SupportInfoResultVo {
  /**  */
  accountBank?: string;

  /**  */
  accountNumber?: string;

  /**  */
  address?: string;

  /**  */
  addressDetail?: string;

  /**  */
  birthday?: string;

  /**  */
  businessPhoneNumber?: string;

  /**  */
  donorId?: string;

  /**  */
  donorType?: string;

  /**  */
  email?: string;

  /**  */
  existYn?: string;

  /**  */
  faxPhone?: string;

  /**  */
  firstName?: string;

  /**  */
  homePhone?: string;

  /**  */
  lastName?: string;

  /**  */
  memberName?: string;

  /**  */
  middleName?: string;

  /**  */
  mobilePhoneNumber?: string;

  /**  */
  nomail?: string;

  /**  */
  optLine?: string;

  /**  */
  orgRec?: string;

  /** 응답코드(성공 : success, 실패 : failed) */
  resultCode?: string;

  /** 응답 메세지 */
  resultMessage?: string;

  /**  */
  zipCode?: string;
}

export interface SupportModuleListVo {
  /**  */
  displayStateName?: string;

  /**  */
  displayStateTypeCode?: string;

  /**  */
  moduleCode?: number;

  /**  */
  moduleName?: string;

  /**  */
  optRadioItems?: string;

  /**  */
  optRadioLabel?: string;

  /**  */
  optSupportAlert?: string;

  /**  */
  relCode?: SupportCodeVo[];

  /** 응답코드(성공 : success, 실패 : failed) */
  resultCode?: string;

  /** 응답 메세지 */
  resultMessage?: string;

  /**  */
  supportAccumulateAmount?: number;

  /**  */
  supportCount?: number;

  /**  */
  supportInduce?: string;

  /**  */
  supportInduceMobile?: string;

  /**  */
  supportRegAmount1?: number;

  /**  */
  supportRegAmount2?: number;

  /**  */
  supportRegAmount3?: number;

  /**  */
  supportRegAmount4?: number;

  /**  */
  supportRegAmount5?: number;

  /**  */
  supportSpecificAmount?: number;
}

export interface URI {
  /**  */
  absolute?: boolean;

  /**  */
  authority?: string;

  /**  */
  fragment?: string;

  /**  */
  host?: string;

  /**  */
  opaque?: boolean;

  /**  */
  path?: string;

  /**  */
  port?: number;

  /**  */
  query?: string;

  /**  */
  rawAuthority?: string;

  /**  */
  rawFragment?: string;

  /**  */
  rawPath?: string;

  /**  */
  rawQuery?: string;

  /**  */
  rawSchemeSpecificPart?: string;

  /**  */
  rawUserInfo?: string;

  /**  */
  scheme?: string;

  /**  */
  schemeSpecificPart?: string;

  /**  */
  userInfo?: string;
}

export interface URL {
  /**  */
  authority?: string;

  /**  */
  content?: object;

  /**  */
  defaultPort?: number;

  /**  */
  file?: string;

  /**  */
  host?: string;

  /**  */
  path?: string;

  /**  */
  port?: number;

  /**  */
  protocol?: string;

  /**  */
  query?: string;

  /**  */
  ref?: string;

  /**  */
  userInfo?: string;
}

export interface UnicefTrkDto {
  /**  */
  trk_REGDATE_8?: string;

  /**  */
  trk_REGTIME_8?: string;

  /**  */
  trk_URL?: string;

  /**  */
  trk_code?: string;

  /**  */
  trk_id?: number;

  /**  */
  trk_regdate?: Date;
}
